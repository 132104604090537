import { ErrorMessage } from "../ErrorMessage/index.js";
import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

const selectOptions = [
  { value: "option1", label: "01:00" },
  { value: "option2", label: "02:00" },
  { value: "option3", label: "03:00" },
  { value: "option4", label: "04:00" },
  { value: "option5", label: "05:00" },
  { value: "option6", label: "06:00" },
  { value: "option7", label: "07:00" },
  { value: "option8", label: "08:00" },
  { value: "option9", label: "09:00" },
  { value: "option10", label: "10:00" },
  { value: "option11", label: "11:00" },
  { value: "option12", label: "12:00" },
  { value: "option13", label: "13:00" },
  { value: "option14", label: "14:00" },
  { value: "option15", label: "15:00" },
  { value: "option16", label: "16:00" },
  { value: "option17", label: "17:00" },
  { value: "option18", label: "18:00" },
  { value: "option19", label: "19:00" },
  { value: "option20", label: "20:00" },
  { value: "option21", label: "21:00" },
  { value: "option22", label: "22:00" },
  { value: "option23", label: "23:00" },
  { value: "option24", label: "00:00" },
];

const placeholderOption = selectOptions.find((option) => option.label === "15:00");

const variants = {
  OutlineBluegray102: "bg-white_A700 border border-bluegray_102 border-solid",
  FillWhiteA700: "bg-white_A700",
  OutlineGray700: "bg-white_A700 border border-gray_700 border-solid",
};
const shapes = { RoundedBorder2: "rounded-radius2" };
const sizes = {
  sm: "lg:p-[4px] xl:p-[5px] p-[6px] 3xl:p-[7px]",
  md: "lg:pb-[5px] xl:pb-[7px] pb-[8px] 3xl:pb-[9px] lg:pt-[10px] xl:pt-[13px] pt-[15px] 3xl:pt-[18px] lg:px-[5px] xl:px-[7px] px-[8px] 3xl:px-[9px]",
};

const SelectBoxcopy = React.forwardRef(
  (
    {
      children,
      placeholder = "Select",
      className,
      options = selectOptions,
      isSearchable = false,
      placeholderClassName = "",
      isMulti = false,
      onChange,
      value,
      errors = [],
      indicator,
      shape,
      variant,
      size,
      ...restProps
    },
    ref
  ) => {
    const [selectedVal, setSelectedVal] = React.useState(value);

    const handleChange = (data) => {
      setSelectedVal(data);
      if (isMulti) {
        onChange?.(data?.map((d) => d.label) || []);
      } else {
        onChange?.(data?.label || placeholder);
      }
    };
    return (
      <>
        <Select
          ref={ref}
          // options={options}
          options={selectOptions}
          className={`${className} ${shapes[shape] || ""} ${
            variants[variant] || ""
          } ${sizes[size] || ""} common-select`}
          placeholder={
            <div className={placeholderClassName}>{placeholder}</div>
          }
          isSearchable={isSearchable}
          isMulti={isMulti}
          components={{
            IndicatorSeparator: () => null,
            ...(indicator && { DropdownIndicator: () => indicator }),
          }}
          value={selectedVal}
          onChange={handleChange}
          styles={{
            container: (provided) => ({
              ...provided,
              zIndex: 0,
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: "transparent",
              border: "0 !important",
              boxShadow: "0 !important",
              minHeight: "auto",
              "&:hover": {
                border: "0 !important",
              },
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected && "#000000",
              backgroundColor: state.isSelected && "#CAD8D4",
              "&:hover": { backgroundColor: "#0976B5", color: "#ffffff" },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "inherit",
            }),
            input: (provided) => ({
              ...provided,
              color: "inherit",
              margin: "0",
              padding: "0",
              // height: "0",
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: "0",
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              paddingTop: "0px",
              paddingBottom: "0px",
            }),
            clearIndicator: (provided) => ({
              ...provided,
              padding: "0",
            }),
            multiValue: (provided) => ({
              ...provided,
              margin: "0",
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              padding: "0",
            }),
            menuPortal: (base) => ({ ...base, zIndex: 999999 }),
          }}
          menuPortalTarget={document.body}
          closeMenuOnScroll={(event) => {
            return event.target.id === "scrollContainer";
          }}
          {...restProps}
        />
        <ErrorMessage errors={errors} />
        {children}
      </>
    );
  }
);

SelectBoxcopy.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  isSearchable: PropTypes.bool,
  placeholderClassName: PropTypes.string,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  shape: PropTypes.oneOf(["RoundedBorder2"]),
  variant: PropTypes.oneOf([
    "OutlineBluegray102",
    "FillWhiteA700",
    "OutlineGray700",
  ]),
  size: PropTypes.oneOf(["sm", "md"]),
};
SelectBoxcopy.defaultProps = {
  placeholder: placeholderOption ? placeholderOption.label : "Select",
  className: "",
  isSearchable: false,
  placeholderClassName: "",
  isMulti: false,
  value: "",
  shape: "",
  variant: "OutlineBluegray102",
  size: "sm",
  options: selectOptions,
  onChange: () => {},
};
export { SelectBoxcopy };