import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, Input, Img, Button, Line, Row, Column } from "components";
import { useNavigate, Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import './App1.css';
import { IoPartlySunnyOutline } from 'react-icons/io5';
import { BiMoon } from 'react-icons/bi';
import { GiKnifeFork } from 'react-icons/gi';
import { GiBed } from 'react-icons/gi';
import { BsSun } from 'react-icons/bs';
import { GiPathDistance } from 'react-icons/gi';
import { BsAirplane } from 'react-icons/bs';
import { BsTrainFreightFront } from 'react-icons/bs';
import { BiBus } from 'react-icons/bi';
import { FaCar } from "react-icons/fa";
import HeaderOTA from 'components/Header/HeaderOTA/index';
import HeaderOTAMobileEpic from "components/Header/HeaderOTAMobileEpic/index";
import LoginPage from "components/Login/index";
import SignupPage from "components/SignUp/index";
import { useAuth } from "AuthContext";
import ContentSetting from 'components/ContentSetting/index';
import { fetchItineraryDetails, fetchItineraryDestination } from 'data/data';
import { useParams } from 'react-router-dom';


const OverviewTP = () => {

  const baseURL = window.location.origin;
  console.log("baseUrl: ", baseURL);

  const navigate = useNavigate();

  const { isLoggedIn } = useAuth();

  // *LOGIN POPUP SETUP*
  const [isPopup1Open, setIsPopup1Open] = useState(false);
  const [isPopup2Open, setIsPopup2Open] = useState(false);
  const [isShowContentSetting, setIsShowContentSetting] = React.useState(false);

  const openPopup1 = () => {
    setIsPopup1Open(true);
  };

  const openPopup2 = () => {
    setIsPopup2Open(true);
  };

  const openPopup3 = () => {
    setIsShowContentSetting(true);
  }

  const closePopup1 = () => {
    setIsPopup1Open(false);
  };

  const closePopup2 = () => {
    setIsPopup2Open(false);
  };

  const closePopup3 = () => {
    setIsShowContentSetting(false);
  }


  const loading = useSelector((state) => state.data.loading);

  const [isLoading, setIsLoading] = useState(false);

  const error = useSelector((state) => state.data.error);

  const messages = [
    "Hold up, we're putting together your travel plan...",
    "Figuring out the cities you'll hit...",
    "Sorting out the attractions for you to check out...",
    "Grabbing the travel routes for your journey...",
    "Fine-tuning your itinerary to make it even better...",
    "Hang tight while we make sure your trip is top-notch"
  ];

  const [text, setText] = useState(messages[0]);

  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];


  // *FETCH TRAVEL PLAN DATA BY TRAVEL PLAN ID*
  // Api V2

  const { creatorId, itineraryId, itineraryTitle } = useParams();

  const [itineraryDetails, setItineraryDetails] = useState(''); //{}
  const [userItineraryActivity, setUserItineraryActivity] = useState([]); //[] array 25
  // const [itineraryId, setItineraryId] = useState(itineraryId); //int
  const [day, setDay] = useState(''); //int
  const [title, setTitle] = useState(''); //string
  const [data3, setData3] = useState([]); //locationImage []
  const [itineraryDestination, setItineraryDestination] = useState([]); //follow locationImage
  const [destination, setDestination] = useState([]); //follow locationImage

  const [startDate, setStartDate] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {

        const data = await fetchItineraryDetails(itineraryId);
        const data2 = await fetchItineraryDestination(itineraryId); //use to fetch destination of the Itinerary

        console.log("ZZDATA: ", data);
        console.log("ZZDATA2: ", data2);

        setItineraryDetails(data);
        setUserItineraryActivity(data.itineraryDetails.userItineraryActivity);
        // setItineraryId(data.itineraryDetails.itineraryId);

        setDay(data.itineraryDetails.day);
        setStartDate(data.itineraryDetails.startDate);
        setTitle(data.itineraryDetails.itineraryTitle);
        setData3(data.itineraryDetails.location); //locationImage

        setItineraryDestination(data2);
        setDestination(data2.itineraryDestination.destination);

        const encodedCorrectTitle = encodeURIComponent(data.itineraryDetails.itineraryTitle);

        try {
          // Check if the URL title matches the correct title, if not, update the URL
          if (itineraryTitle !== encodedCorrectTitle) {
            navigate(`/itinerary/${creatorId}/${itineraryId}/${encodedCorrectTitle}`, { replace: true });
          }
        } catch (error) {
          console.error('Error fetching itinerary details:', error);
        }

        setIsLoading(false);

      } catch (error) {
        console.error('Error fetching itinerary details:', error);

        setIsLoading(false);
      }
    };

    fetchData();

  }, []);

  console.log("XXItinerary Details (All): ", itineraryDetails)
  console.log("XXUser Itinerary Activity: ", userItineraryActivity)
  console.log("XXItinerary Id: ", itineraryId)
  console.log("XXItinerary Details (Day): ", day)
  console.log("XXUser Itinerary Ttitle: ", title)
  console.log("XXLocation Image: ", data3)
  console.log("XXItineraryDetailsPC: ", itineraryDetails);
  console.log("XXDestination: ", destination);

  const formatDate1 = (receivedDate, day) => {
    const currentDate = new Date(receivedDate);
    const updatedDate = new Date(currentDate.getTime());
    const year = updatedDate.getFullYear();

    updatedDate.setDate(updatedDate.getDate() + day - 1);

    // Ensure month and day are two digits
    const month = String(updatedDate.getMonth() + 1).padStart(2, '0');
    const dayOfMonth = String(updatedDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${dayOfMonth}`;
  };

  const getNextDayDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const dayOfMonth = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${dayOfMonth}`;
  };



  // bila dah ada data destination, days, date : tukar 
  const receivedData = {
    message: destination,
    days: day,
    date: new Date(),
    itineraryId: itineraryId,
  }


  // *ARRAGE THE ACTIVITIES INTO AN ITINERARY (userItineraryActivity)*

  // Define custom property names for each period
  const periodPropertyNames = {
    morning: {
      activity: 'morningactivity',
      place: 'morningplace',
      comment: 'mcomment',
      date: 'date',
      image: 'morningimage'
    },
    lunch: {
      place: 'lunch',
      date: 'date',
      image: 'lunchimage'
    },
    afternoon: {
      activity: 'afternoonactivity',
      place: 'afternoonplace',
      comment: 'acomment',
      date: 'date',
      image: 'afternoonimage'
    },
    evening: {
      activity: 'eveningactivity',
      place: 'eveningplace',
      comment: 'ecomment',
      date: 'date',
      image: 'eveningimage'
    },
    dinner: {
      place: 'dinner',
      date: 'date',
      image: 'dinnerimage'
    },
  };

  // Define the order of periods
  const periodOrder = ['morning', 'lunch', 'afternoon', 'evening', 'dinner'];

  // Function to parse time into comparable values
  const parseTime = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (modifier === 'pm' && hours !== 12) hours += 12;
    if (modifier === 'am' && hours === 12) hours = 0;

    return hours * 60 + minutes; // Convert to total minutes for easy comparison
  };

  // Group the items by day
  const groupedByDay = userItineraryActivity.reduce((acc, activity) => {
    const day = parseInt(activity.day.replace('Day ', ''), 10);
    const period = activity.period ? activity.period.toLowerCase().split(' ')[0] : '';

    // Initialize the day and activities array if not already present
    acc[day] = acc[day] || { day: day, activities: [] };

    // Handle custom property names, assigning defaults for missing periods
    const customNames = periodPropertyNames[period] || {
      activity: 'genericactivity',
      place: 'genericplace',
      comment: 'genericcomment',
      date: 'date',
    };
    const customActivity = {};

    // Map custom property names to activity data
    Object.entries(customNames).forEach(([key, customName]) => {
      customActivity[customName] = activity[key];
    });

    customActivity.time = activity.time; // Add time to sort later
    customActivity.period = period; // Add period to sort by period

    acc[day].activities.push(customActivity);

    return acc;
  }, {});

  // Sort the activities within each day by period and then by time
  Object.values(groupedByDay).forEach((dayObj) => {
    dayObj.activities.sort((a, b) => {
      // Sort by period
      const periodComparison = periodOrder.indexOf(a.period) - periodOrder.indexOf(b.period);

      if (periodComparison !== 0) {
        return periodComparison;
      }

      // If periods are the same, sort by time
      return parseTime(a.time) - parseTime(b.time);
    });
  });

  // Convert the keys to an array, sort them, and create a new array with sorted keys and data
  const sortedKeys = Object.keys(groupedByDay).map(Number).sort((a, b) => a - b);
  const sortedGroupedByDay = sortedKeys.map((key) => groupedByDay[key]);

  const data = sortedGroupedByDay;
  console.log("ZZData Overview: ", data);

  // Define the start day (e.g., from Day 1 onwards)
  const startDay = 1;

  // Filter and sort data
  const filteredData = data
    .filter((item) => item.day >= startDay) // Filter starting from startDay
    .sort((a, b) => a.day - b.day); // Sort by day in ascending order


  // *GAMBAR?????*
  function findThumbnailURLIndex(data3, title) {
    for (let i = 0; i < data3.length; i++) {
      if (data3[i].title === title) {
        return i;
      }
    }

    return -1; // If title is not found
  }

  function findThumbnailURLIndex1(data3, title) {
    for (let i = 0; i < data3.length; i++) {
      if (data3[i].title === title) {
        return i;
      }
    }

    return -1; // If title is not found
  }

  function findThumbnailURLIndex2(data3, title) {
    for (let i = 0; i < data3.length; i++) {
      if (data3[i].title === title) {
        return i;
      }
    }

    return -1; // If title is not found
  }

  const getProcessedImageUrl = (image) => {
    let imageUrl = '';
    let url = 'https://halaltravel.ai/ht'

    if (image) {
      if (image.indexOf("/") > -1) {
        // If the image path contains a "/", use the coverimage API endpoint
        imageUrl = url + `/api/chatgpt/user/itinerary/coverimage` + image;
      } else {
        // Otherwise, use the restaurant images API endpoint
        imageUrl = url + `/api/restaurant/images/` + image;
      }
    }

    return imageUrl;
  };


  // *NAVIGATION ICON BUS*
  const handleButtonClick = (date) => {
    if (isLoggedIn) {
      navigate("/bus-landing-page", { state: { destination, date } });
    } else {
      setIsPopup1Open(true);
    }
  };



  return (
    <>
      <div id="myElement" className="bg-[#EAEBEF] flex flex-col font-ptsans items-center justify-start mx-[auto] w-[100%]">

        {/*Mobile View*/}
        <div className="absolute visible lg:invisible lg:hidden w-full">
          <div className="bg-[#EAEBEF] flex flex-col font-montserrat items-center justify-start mx-auto w-full">
            {/* <HeaderOTAMobileEpic openPopup1={openPopup1} className="visible fixed overflow-hidden lg:invisible lg:hidden" /> */}
            <div className="fixed w-full z-10">
              <HeaderOTA openPopup1={openPopup1} className="relative hidden lg:visible" />
              <LoginPage isOpen={isPopup1Open} openPopup2={openPopup2} closePopup1={closePopup1} />
              <SignupPage isOpen={isPopup2Open} closePopup2={closePopup2} />
              <HeaderOTAMobileEpic openPopup1={openPopup1} className="visible fixed overflow-hidden lg:invisible lg:hidden" />
            </div>


            {/* <div className='absolute visible lg:invisible  bg-gray-200'> */}
            <div className="w-full xs:pt-[160px] lg:pt-[92px]">
              <div className="justify-between flex bg-white shadow-lg p-4 w-full">
                <Row className="grid grsid-row-2 grid-flow-col">
                  <text className="font-montserrat ml-2 text-[50px] sm:text-[48px] md:text-[40px] xl:text-[40px]" style={{ color: '#45B9B4' }}>
                    {/* {day} Days Trip To {receivedData.message} */}
                    {title}
                  </text>
                </Row>
              </div>
              <div className="place-items-center grid bg-white shadow-inner p-4 w-full"  >
                <div>
                  <text
                    className="font-montserrat common-pointer font-bold border-b border-b-2 border-b-[#6392F9] text-[#6392F9] p-3 md:text-[30px] xl:text-[30px]"
                  // onClick={() => {

                  //   const dataToPass = {
                  //     message: receivedData.message,
                  //     days: receivedData.days,
                  //     theme: receivedData.theme,
                  //     date: receivedData.date
                  //   };
                  //   navigate('/ota1', { state: dataToPass });
                  // }}
                  >
                    Overview
                  </text>
                  {/* <text
                  className={`font-montserrat common-pointer ml-10 sm:ml-4 md:ml-6 lg:ml-10 hover:border-b hover:border-b-2 hover:border-b-[#6392F9] hover:text-[#6392F9] p-3 md:text-[30px] xl:text-[30px] ${data?.length > 0 ? 'cursor-pointer' : ''
                    }`}
                  onClick={() => {
                    if (!data?.length) {



                    } else {
                      const dataToPass = {
                        message: receivedData.message,
                        days: receivedData.days,
                        theme: receivedData.theme,
                        date: receivedData.date
                      };
                      navigate('/ota2', { state: dataToPass });
                    }
                  }}
                >
                  Editable View
                </text> */}
                  <text
                    className={`font-montserrat common-pointer ml-10 sm:ml-4 md:ml-6 lg:ml-10 hover:border-b hover:border-b-2 hover:border-b-[#6392F9] hover:text-[#6392F9] p-3 md:text-[30px] xl:text-[30px] ${data?.length > 0 ? 'cursor-pointer' : ''
                      }`}
                    onClick={() => {
                      //  setIsPopup1Open(true);

                      const dataToPass = {
                        location: destination,
                        startDate: startDate,
                        day: day
                      };

                      //clear
                      localStorage.removeItem("INTINERARY_" + itineraryId + "_STARTDATE");
                      localStorage.removeItem("INTINERARY_" + itineraryId + "_DAY");

                      navigate(`/itinerary-save/${creatorId}/${itineraryId}/${itineraryTitle}/editableView`, { state: dataToPass });

                      ;
                    }}
                  >
                    Editable View
                  </text>
                </div>
              </div>

              <div className='font-montserrat h-[100%] w-[100%]' style={{ backgroundColor: isLoading ? '#F5F5F5' : '#F5F5F5' }}>
                <div className="flex items-center justify-center" style={{ backgroundColor: isLoading ? '#F5F5F5' : '#F5F5F5' }}>

                  {/* kosong */}
                  {!isLoading ? (
                    filteredData.length === 0 ? (
                      // Message when the data array is empty
                      <div style={{ backgroundColor: '#F5F5F5' }} className="flex w-full h-full justify-center items-start text-center py-10">
                        <span className="text-gray-500 font-medium text-[20px] py-20">
                          No activities found. <br /> Start adding your activities in the Editable View.
                        </span>
                      </div>
                    ) : (

                      <div className={`grid grid-cols-1 md:grid-cols-1 gap-2`}>
                        {filteredData.map((item) => (
                          <div key={item} className="flex items-center justify-center">
                            <div style={{ backgroundColor: '#F5F5F5' }} className="flex items-center justify-center w-full">
                              <div className="w-full px-12">
                                <div className={`p-3 mt-10 rounded-lg grid grid-row-2 grid-flow-col w-full mt-5`}>
                                  <div className="text-center">
                                    <span className="font-bold justify-center block sm:text-[8px] md:text-[60px] lg:text-[9px]">Day {item.day}</span>
                                    <span style={{ color: '#008009' }} className="font-bold block font-semibold justify-center sm:text-[6px] md:text-[30px] lg:text-[6px]">
                                      {item.activities[0]?.date}
                                    </span>
                                  </div>
                                </div>

                                <div className="mt-10 w-full flex flex-col items-start">
                                  <div className="flex items-center mb-2">
                                    <GiPathDistance color="#008009" size={50}
                                      className="rounded-lg w-10 h-10" alt="route" />
                                    <text style={{ color: '#008009', marginLeft: 11 }} className="text-[37px] font-bold ml-2 font-montserrat">
                                      Transportation
                                    </text>
                                    <div className="flex" style={{ marginLeft: '30' }}>
                                      <a href={`https://kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_cid=${creatorId}&lc=en&url=%2Fflights`} target="_blank" rel="noopener noreferrer">
                                        <BsAirplane color="#008009" size={50} className="rounded-lg w-10 h-10" alt="route" style={{ marginLeft: '20px', marginRight: '20px' }} />
                                      </a>
                                      <a href={`https://kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_cid=${creatorId}&lc=en&url=%2Fcars`} target="_blank" rel="noopener noreferrer">
                                        <FaCar color="#008009" size={50} className="rounded-lg w-10 h-10" alt="route" style={{ marginLeft: '0px', marginRight: '20px' }} />
                                      </a>
                                      <a href="https://online.ktmb.com.my" target="_blank" rel="noopener noreferrer">
                                        <BsTrainFreightFront color="#008009" size={50}
                                          className="rounded-lg w-10 h-10" alt="route" style={{ marginLeft: '0px', marginRight: '20px' }} />
                                      </a>
                                      {baseURL === 'https://vm.epictravel.ai' || 'http://localhost:3000' ? (
                                        <a
                                          // onClick={() => {
                                          //   handleButtonClick(item.date);
                                          // }}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <BiBus color="#008009" size={50} className="rounded-lg w-10 h-10" alt="route" />
                                        </a>
                                      ) : (
                                        <a href="https://gohub.com.my" target="_blank" rel="noopener noreferrer">
                                          <BiBus color="#008009" size={50} className="rounded-lg w-10 h-10" alt="route" />
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>


                                {/* Morning Activities */}
                                <div className="#F5F5F5 w-full flex flex-col items-start">
                                  <div className="flex items-center mb-2">
                                    <BsSun color="#008009" size={50} className="rounded-lg w-10 h-10" alt="route" />
                                    <span style={{ color: '#008009', fontWeight: '800', marginLeft: 11 }} className="text-[37px] ml-2">
                                      Morning
                                    </span>
                                  </div>
                                  <div className="ml-4 mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginLeft: '0px' }}>
                                      {/* Content Area with Dotted Border */}
                                      <div
                                        className="flex flex-col pl-4 relative"
                                        style={{
                                          borderLeft: '2px dotted transparent', // Invisible border for spacing
                                          backgroundImage: 'linear-gradient(#008009 50%, transparent 0)',
                                          backgroundSize: '1.5px 10px', // Adjusts size of dots and gap
                                          backgroundRepeat: 'repeat-y',
                                          padding: '8px', // Adjust padding to match the dotted border width
                                        }}
                                      >
                                        <span className='mb-4'></span>

                                        {item.activities.some(activity => activity.morningactivity) ? (
                                          item.activities.filter(activity => activity.morningactivity).map((activity, index) => (
                                            <div key={index} className="pl-10 flex items-start text-[27px] mb-4">
                                              {/* Set a fixed width for the time */}
                                              <span className="font-montserrat" style={{ fontSize: '27px', fontWeight: '550', color: 'black', width: '160px' }}>
                                                {activity.time}
                                              </span>
                                              <span className="font-montserrat" style={{ fontSize: '27px', fontWeight: '450', color: 'black', width: '5px' }}>
                                                :
                                              </span>
                                              <div className="flex-1 text-[27px] pl-5">
                                                <span style={{ fontSize: '27px', fontWeight: '450', color: 'black' }}>
                                                  {activity.morningactivity.replace(activity.morningplace, "")}{' '}
                                                  <span className="text-[#008009] underline" style={{ fontStyle: 'italic' }}>
                                                    <Link
                                                      to={{
                                                        pathname: `/travelplan/blog/display/${creatorId}/${activity.morningplace}/${itineraryId}`,
                                                        state: { itineraryId }
                                                      }}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {activity.morningplace}
                                                    </Link>
                                                  </span>
                                                  . {activity.mcomment}

                                                  <div className="mt-4 mb-8" style={{ display: 'flex' }}>
                                                    {activity.morningimage ? (
                                                      <a
                                                        href={getProcessedImageUrl(activity.morningimage)} // Make sure this is a full URL or a valid path
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <img
                                                          className="rounded rounded-2xl drop-shadow-lg"
                                                          src={getProcessedImageUrl(activity.morningimage)}
                                                          alt="Display"
                                                          style={{
                                                            width: '460px',
                                                            height: '360px',
                                                            marginTop: 5,
                                                            objectFit: 'cover',
                                                            // border: '1px solid #E0E0E0',
                                                            // borderRadius: '10px',
                                                            background: '#E0E0E0'
                                                          }}
                                                        />
                                                      </a>
                                                    ) : (
                                                      <span></span>
                                                    )}
                                                  </div>
                                                </span>
                                              </div>
                                            </div>
                                          ))
                                        ) : (
                                          <span className="pl-10 text-[27px] italic text-gray-500">No activity</span>
                                        )}

                                        <span className='mb-4'></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Lunch Activities */}
                                <div className="mt-1 #F5F5F5 w-full flex flex-col items-start">
                                  <div className="flex items-center mb-2">
                                    <GiKnifeFork color="#008009" size={50} className="rounded-lg w-10 h-10" alt="route" />
                                    <span style={{ color: '#008009', fontWeight: '800', marginLeft: 11 }} className="text-[37px] ml-2">
                                      Lunch
                                    </span>
                                  </div>
                                  <div className="ml-4 mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginLeft: '0px' }}>
                                      <div
                                        className="flex flex-col pl-4 relative"
                                        style={{
                                          borderLeft: '2px dotted transparent', // Invisible border for spacing
                                          backgroundImage: 'linear-gradient(#008009 50%, transparent 0)',
                                          backgroundSize: '1.5px 10px', // Adjusts size of dots and gap
                                          backgroundRepeat: 'repeat-y',
                                          padding: '8px', // Adjust padding to match the dotted border width
                                        }}
                                      >
                                        <span className='mb-4'></span>

                                        {item.activities.some(activity => activity.lunch) ? (
                                          item.activities.filter(activity => activity.lunch).map((activity, index) => (
                                            <div key={index} className="pl-10 flex items-start text-[27px] mb-4">
                                              <span className="font-montserrat" style={{ fontSize: '27px', fontWeight: '550', color: 'black', width: '160px' }}>
                                                {activity.time}
                                              </span>
                                              <span className="font-montserrat" style={{ fontSize: '27px', fontWeight: '450', color: 'black', width: '5px' }}>
                                                :
                                              </span>
                                              <div className="flex-1 text-[27px] pl-5">
                                                <span style={{ fontSize: '27px', fontWeight: '450', color: 'black' }}>
                                                  Lunch at {activity.lunch}
                                                </span>
                                                <div className="mt-4 mb-8" style={{ display: 'flex' }}>
                                                  {activity.lunchimage ? (
                                                    <a
                                                      href={getProcessedImageUrl(activity.lunchimage)} // Make sure this is a full URL or a valid path
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      <img
                                                        className="rounded rounded-2xl drop-shadow-lg"
                                                        src={getProcessedImageUrl(activity.lunchimage)}
                                                        alt="Display"
                                                        style={{
                                                          width: '460px',
                                                          height: '360px',
                                                          marginTop: 5,
                                                          objectFit: 'cover',
                                                          // border: '1px solid #E0E0E0',
                                                          // borderRadius: '10px',
                                                          background: '#E0E0E0'
                                                        }}
                                                      />
                                                    </a>
                                                  ) : (
                                                    <span></span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ))
                                        ) : (
                                          <span className="pl-10 text-[27px] italic text-gray-500">No activity</span>
                                        )}

                                        <span className='mb-4'></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Afternoon Activities */}
                                <div className="mt-1 #F5F5F5 w-full flex flex-col items-start">
                                  <div className="flex items-center mb-2">
                                    <BsSun color="#008009" size={50} className="rounded-lg w-10 h-10" alt="route" />
                                    <span style={{ color: '#008009', fontWeight: '800', marginLeft: 11 }} className="text-[37px] ml-2">
                                      Afternoon
                                    </span>
                                  </div>
                                  <div className="ml-4 mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginLeft: '0px' }}>
                                      {/* Content Area with Dotted Border */}
                                      <div
                                        className="flex flex-col pl-4 relative"
                                        style={{
                                          borderLeft: '2px dotted transparent', // Invisible border for spacing
                                          backgroundImage: 'linear-gradient(#008009 50%, transparent 0)',
                                          backgroundSize: '1.5px 10px', // Adjusts size of dots and gap
                                          backgroundRepeat: 'repeat-y',
                                          padding: '8px', // Adjust padding to match the dotted border width
                                        }}
                                      >
                                        <span className='mb-4'></span>

                                        {item.activities.some(activity => activity.afternoonactivity) ? (
                                          item.activities.filter(activity => activity.afternoonactivity).map((activity, index) => (
                                            <div key={index} className="pl-10 flex items-start text-[27px] mb-4">
                                              {/* Set a fixed width for the time */}
                                              <span className="font-montserrat" style={{ fontSize: '27px', fontWeight: '550', color: 'black', width: '160px' }}>
                                                {activity.time}
                                              </span>
                                              <span className="font-montserrat" style={{ fontSize: '27px', fontWeight: '450', color: 'black', width: '5px' }}>
                                                :
                                              </span>
                                              <div className="flex-1 text-[27px] pl-5">
                                                <span style={{ fontSize: '27px', fontWeight: '450', color: 'black' }}>
                                                  {activity.afternoonactivity.replace(activity.afternoonplace, "")}{' '}
                                                  <span className="text-[#008009] underline" style={{ fontStyle: 'italic' }}>
                                                    <Link
                                                      to={{
                                                        pathname: `/travelplan/blog/display/${creatorId}/${activity.afternoonplace}/${itineraryId}`,
                                                        state: { itineraryId }
                                                      }}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {activity.afternoonplace}
                                                    </Link>
                                                  </span>
                                                  . {activity.acomment}

                                                  <div className="mt-4 mb-8" style={{ display: 'flex' }}>
                                                    {activity.afternoonimage ? (
                                                      <a
                                                        href={getProcessedImageUrl(activity.afternoonimage)} // Make sure this is a full URL or a valid path
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <img
                                                          className="rounded rounded-2xl drop-shadow-lg"
                                                          src={getProcessedImageUrl(activity.afternoonimage)}
                                                          alt="Display"
                                                          style={{
                                                            width: '460px',
                                                            height: '360px',
                                                            marginTop: 5,
                                                            objectFit: 'cover',
                                                            // border: '1px solid #E0E0E0',
                                                            // borderRadius: '10px',
                                                            background: '#E0E0E0'
                                                          }}
                                                        />
                                                      </a>
                                                    ) : (
                                                      <span></span>
                                                    )}
                                                  </div>
                                                </span>
                                              </div>
                                            </div>
                                          ))
                                        ) : (
                                          <span className="pl-10 text-[27px] italic text-gray-500">No activity</span>
                                        )}

                                        <span className='mb-4'></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                {/* Evening Activities */}
                                <div className="mt-1 #F5F5F5 w-full flex flex-col items-start">
                                  <div className="flex items-center mb-2">
                                    <BsSun color="#008009" size={50} className="rounded-lg w-10 h-10" alt="route" />
                                    <span style={{ color: '#008009', fontWeight: '800', marginLeft: 11 }} className="text-[37px] ml-2">
                                      Evening
                                    </span>
                                  </div>
                                  <div className="ml-4 mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginLeft: '0px' }}>
                                      {/* Content Area with Dotted Border */}
                                      <div
                                        className="flex flex-col pl-4 relative"
                                        style={{
                                          borderLeft: '2px dotted transparent', // Invisible border for spacing
                                          backgroundImage: 'linear-gradient(#008009 50%, transparent 0)',
                                          backgroundSize: '1.5px 10px', // Adjusts size of dots and gap
                                          backgroundRepeat: 'repeat-y',
                                          padding: '8px', // Adjust padding to match the dotted border width
                                        }}
                                      >
                                        <span className='mb-4'></span>

                                        {item.activities.some(activity => activity.eveningactivity) ? (
                                          item.activities.filter(activity => activity.eveningactivity).map((activity, index) => (
                                            <div key={index} className="pl-10 flex items-start text-[27px] mb-4">
                                              {/* Set a fixed width for the time */}
                                              <span className="font-montserrat" style={{ fontSize: '27px', fontWeight: '550', color: 'black', width: '160px' }}>
                                                {activity.time}
                                              </span>
                                              <span className="font-montserrat" style={{ fontSize: '27px', fontWeight: '450', color: 'black', width: '5px' }}>
                                                :
                                              </span>
                                              <div className="flex-1 text-[27px] pl-5">
                                                <span style={{ fontSize: '27px', fontWeight: '450', color: 'black' }}>
                                                  {activity.eveningactivity.replace(activity.eveningplace, "")}{' '}
                                                  <span className="text-[#008009] underline" style={{ fontStyle: 'italic' }}>
                                                    <Link
                                                      to={{
                                                        pathname: `/travelplan/blog/display/${creatorId}/${activity.eveningplace}/${itineraryId}`,
                                                        state: { itineraryId }
                                                      }}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {activity.eveningplace}
                                                    </Link>
                                                  </span>
                                                  . {activity.ecomment}

                                                  <div className="mt-4 mb-8" style={{ display: 'flex' }}>
                                                    {activity.eveningimage ? (
                                                      <a
                                                        href={getProcessedImageUrl(activity.eveningimage)} // Make sure this is a full URL or a valid path
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <img
                                                          className="rounded rounded-2xl drop-shadow-lg"
                                                          src={getProcessedImageUrl(activity.eveningimage)}
                                                          alt="Display"
                                                          style={{
                                                            width: '460px',
                                                            height: '360px',
                                                            marginTop: 5,
                                                            objectFit: 'cover',
                                                            // border: '1px solid #E0E0E0',
                                                            // borderRadius: '10px',
                                                            background: '#E0E0E0'
                                                          }}
                                                        />
                                                      </a>
                                                    ) : (
                                                      <span></span>
                                                    )}
                                                  </div>
                                                </span>
                                              </div>
                                            </div>
                                          ))
                                        ) : (
                                          <span className="pl-10 text-[27px] italic text-gray-500">No activity</span>
                                        )}

                                        <span className='mb-4'></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Dinner Activities */}
                                <div className="mt-1 #F5F5F5 w-full flex flex-col items-start">
                                  <div className="flex items-center mb-2">
                                    <GiKnifeFork color="#008009" size={50} className="rounded-lg w-10 h-10" alt="route" />
                                    <span style={{ color: '#008009', fontWeight: '800', marginLeft: 11 }} className="text-[37px] ml-2">
                                      Dinner
                                    </span>
                                  </div>
                                  <div className="ml-4 mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginLeft: '0px' }}>
                                      <div
                                        className="flex flex-col pl-4 relative"
                                        style={{
                                          borderLeft: '2px dotted transparent', // Invisible border for spacing
                                          backgroundImage: 'linear-gradient(#008009 50%, transparent 0)',
                                          backgroundSize: '1.5px 10px', // Adjusts size of dots and gap
                                          backgroundRepeat: 'repeat-y',
                                          padding: '8px', // Adjust padding to match the dotted border width
                                        }}
                                      >
                                        <span className='mb-4'></span>

                                        {item.activities.some(activity => activity.dinner) ? (
                                          item.activities.filter(activity => activity.dinner).map((activity, index) => (
                                            <div key={index} className="pl-10 flex items-start text-[27px] mb-4">
                                              <span className="font-montserrat" style={{ fontSize: '27px', fontWeight: '550', color: 'black', width: '160px' }}>
                                                {activity.time}
                                              </span>
                                              <span className="font-montserrat" style={{ fontSize: '27px', fontWeight: '450', color: 'black', width: '5px' }}>
                                                :
                                              </span>
                                              <div className="flex-1 text-[27px] pl-5">
                                                <span style={{ fontSize: '27px', fontWeight: '450', color: 'black' }}>
                                                  Dinner at {activity.dinner}
                                                </span>
                                                <div className="mt-4 mb-8" style={{ display: 'flex' }}>
                                                  {activity.dinnerimage ? (
                                                    <a
                                                      href={getProcessedImageUrl(activity.dinnerimage)} // Make sure this is a full URL or a valid path
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      <img
                                                        className="rounded rounded-2xl drop-shadow-lg"
                                                        src={getProcessedImageUrl(activity.dinnerimage)}
                                                        alt="Display"
                                                        style={{
                                                          width: '460px',
                                                          height: '360px',
                                                          marginTop: 5,
                                                          objectFit: 'cover',
                                                          // border: '1px solid #E0E0E0',
                                                          // borderRadius: '10px',
                                                          background: '#E0E0E0'
                                                        }}
                                                      />
                                                    </a>
                                                  ) : (
                                                    <span></span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ))
                                        ) : (
                                          <span className="pl-10 text-[27px] italic text-gray-500">No activity</span>
                                        )}

                                        <span className='mb-4'></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                {/* Bedtime  */}
                                <div className="mt-1 w-full flex flex-col items-start mb-10">
                                  {(() => {
                                    // Get the current date
                                    const currentDate = new Date(receivedData.date);
                                    const updatedDate = new Date(currentDate.getTime());
                                    const updatedDate1 = new Date(currentDate.getTime());
                                    updatedDate.setDate(updatedDate.getDate() + item.day - 1);

                                    // Format the date as "YYYY-MM-DD"
                                    const year = updatedDate1.getFullYear();
                                    const month = String(updatedDate1.getMonth() + 1).padStart(2, '0');
                                    const day = String(updatedDate1.getDate()).padStart(2, '0');

                                    const year1 = updatedDate.getFullYear();
                                    const month1 = String(updatedDate.getMonth() + 1).padStart(2, '0');
                                    const day1 = String(updatedDate.getDate()).padStart(2, '0');

                                    // const formattedDate1 = `${year}-${month}-${day}`;
                                    // const formattedDate2 = `${year1}-${month1}-${day1}`;

                                    const formattedDate1 = formatDate1(receivedData.date, item.day);
                                    const formattedDate2 = getNextDayDate(formatDate1(receivedData.date, item.day));

                                    return (
                                      // <span style={{ color: '#008009' }} className="font-bold block font-semibold justify-center font-montserrat sm:text-[6px] md:text-[30px] lg:text-[6px]">
                                      //   &nbsp;&nbsp; {formattedDate}
                                      // </span>

                                      <div className="flex items-center mb-10">
                                        <GiBed
                                          color="#008009"
                                          size={50}
                                          className="rounded-lg w-10 h-10" // Set fixed width and height
                                          alt="route"
                                        />
                                        <text style={{ color: '#008009', ontWeight: '600' }} className="text-[37px] ml-2 font-montserrat">
                                          Bedtime-&nbsp;&nbsp;
                                          <text style={{ color: '#008009', ontWeight: '300' }} className=' text-[37px] '>
                                            Hotels Deals in&nbsp;
                                          </text>
                                          <text style={{ color: '#008009', ontWeight: '300' }} className=' text-[37px] underline'>
                                            {/* <a
                                            href={`http://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/${receivedData.message}/${formattedDate1}/${formattedDate2}?sort=distance_a`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {receivedData.message}
                                          </a> */}

                                            {
                                              (() => {
                                                // Logic to get formattedDate1 and formattedDate2                                       

                                                // Prepare the URL based on the condition
                                                let urlmain;
                                                if (receivedData.message === 'Forest City') {
                                                  // urlmain = `https://kayak.com.my/in?a=kan_262812_573418&lc=en&url=%2Fhotels/Forest-City-Marina-Hotel,Gelang-Patah,Malaysia-p2664154-h2767708/${formattedDate1}/${formattedDate3}?sort=distance_a`;
                                                  urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Forest-City-Marina-Hotel,Gelang-Patah,Malaysia-p2664154-h2767708/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                }
                                                else if (receivedData.message === 'Perak') {
                                                  urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Perak,Malaysia-p21085/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                }
                                                else if (receivedData.message === 'Sabah') {
                                                  urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Sabah,Malaysia-p21083/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                }
                                                else if (receivedData.message === 'Pahang') {
                                                  urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Pahang,Malaysia-p21086/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                }
                                                else if (receivedData.message === 'Penang') {
                                                  urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Penang,Malaysia-p247880/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                }
                                                else if (receivedData.message === 'Sarawak') {
                                                  urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Sarawak,Malaysia-p21082/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                }
                                                else if (receivedData.message === 'Kedah') {
                                                  urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Kedah,Malaysia-p21092/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                }
                                                else if (receivedData.message === 'Selangor') {
                                                  urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Selangor,Malaysia-p21081/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                }
                                                else if (receivedData.message === 'Negeri Sembilan') {
                                                  urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Negeri-Sembilan,Malaysia-p21087/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                }
                                                else if (receivedData.message === 'Kelantan') {
                                                  urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Kelantan-p21088/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                }
                                                else {
                                                  let encodedCityName = encodeURIComponent(receivedData.message);
                                                  urlmain = `http://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/${encodedCityName}/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                }

                                                // Log the URL being navigated to
                                                console.log(`Navigating to web: ${urlmain}`);

                                                return (
                                                  <a
                                                    href={urlmain}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    {receivedData.message}
                                                  </a>
                                                );
                                              })()
                                            }
                                          </text>
                                        </text>
                                      </div>
                                    );
                                  })()}
                                </div>

                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  ) : (

                    <div className="h-[100%] w-[100%] mt-30 flex items-center justify-center flex-col py-5"  >
                      <span style={{ fontSize: '30px', fontWeight: 'normal' }} className="mb-5">{text}</span>
                      <CircularProgress size={30} sx={{ color: 'black' }} />
                    </div>

                  )}

                </div>

                {!isLoading || !data?.length && (
                  <div className="h-[100%] w-[100%] flex items-center justify-center flex-col" style={{ backgroundColor: '#F5F5F5', marginTop: '0px', padding: '20px' }}>
                    {/* <span className="text-center mb-4" style={{ fontSize: '30px', fontWeight: 'bold',marginTop: '300px' }}>
                        Oops, looks like the server is experiencing high demand. Please try again.{loading}
                      </span>
                      <Button
                        className="flex items-center justify-center rounded-[10px] px-3 py-2"
                        style={{ backgroundColor: '#00a19a', width: '460px', height: '80px' }}
                        size="smIcn"
                        onClick={handleNavigate689}
                      >
                        <text style={{ fontSize: '30px', fontWeight: 'bold' }} className="uppercase font-montserrat text-[25px] text-white">Regenerate itinerary</text>
                      </Button> */}
                  </div>
                )
                }

              </div>

            </div>
          </div>
        </div>



        {/*Web View*/}

        <div className="absolute invisible lg:visible bg-[#EAEBEF] flex flex-col font-montserrat items-center justify-start mx-[auto] w-[100%]">
          {/* <HeaderOTA openPopup1={openPopup1} className="relative hidden lg:visible" />
          <LoginPage isOpen={isPopup1Open} openPopup2={openPopup2} closePopup1={closePopup1} />
          <SignupPage isOpen={isPopup2Open} closePopup2={closePopup2} /> */}
          <div className="fixed w-full z-10">
            <HeaderOTA openPopup1={openPopup1} className="relative hidden lg:visible" />
            <LoginPage isOpen={isPopup1Open} openPopup2={openPopup2} closePopup1={closePopup1} />
            <SignupPage isOpen={isPopup2Open} closePopup2={closePopup2} />
            <HeaderOTAMobileEpic openPopup1={openPopup1} className="visible fixed overflow-hidden lg:invisible lg:hidden" />
          </div>

          <div className="w-full xs:pt-[160px] lg:pt-[92px]">
            <div
              className="flex bg-white shadow-lg p-4 w-[100%]"
              style={{ backgroundColor: "#F5F5F5" }}
            >
              <Row className="w-[100%] ">
                <div className="text-left w-[50%]">
                  <text
                    className="font-montserrat ml-2 text-[20px] sm:text-[18px] md:text-[20px] xl:text-[20px]"
                    style={{ color: "#45B9B4" }}
                  >
                    {title}
                    {/* (ID: {receivedData.itineraryId}) */}
                  </text>
                </div>
                {/* <Img
                src="images/edit.svg"
                className="common-pointer h-[25px] sm:h-[20px] md:h-[25px] xl:h-[25px] md:ml-[0] sm:ml-[0] xl:ml-[10px] ml-[10px] object-cover md:w-[100%] sm:w-[100%] xl:w-[25px] w-[25px]"
                alt="edit"
                /> */}
                <div className=" w-[50%] text-right">
                  {/* <button class="mr-1 bg-white  rounded-xl border border-[#45B9B4] font-bold py-1 px-2 inline-flex items-center"
                  onClick={openPopup3}
                >
                  <Icon
                    path={mdiBookmarkOutline}
                    size={0.8}
                    className="mr-1 text-[#45B9B4]"
                  />
                  <span className="text-[#45B9B4] font-montserrat">Save Travel Plan</span>
                </button> */}
                </div>
                <ContentSetting showContentSetting={isShowContentSetting} closePopup3={closePopup3} />

              </Row>
            </div>

            <div className="place-items-center grid bg-white shadow-inner p-4 w-full" style={{ backgroundColor: '#F5F5F5' }}>
              <div>
                <text
                  className="font-montserrat common-pointer font-bold border-b border-b-2 border-b-[#6392F9] text-[#6392F9] p-3"
                // onClick={() => setIsPopup1Open(true)}
                // onClick={() => {
                //   const dataToPass = {
                //     message: receivedData.message,
                //     days: receivedData.days,
                //     theme: receivedData.theme,
                //     date: receivedData.date
                //   };
                //   navigate('/ota1', { state: dataToPass });
                // }}
                >
                  Overview
                </text>
                {/* <text
                className="font-montserrat common-pointer ml-10 sm:ml-4 md:ml-6 lg:ml-10 hover:border-b hover:border-b-2 hover:border-b-[#6392F9] hover:text-[#6392F9] p-3"

                onClick={() => {
                  setIsPopup1Open(true);
                  if (!data?.length) {
                  }
                  else {
                    const dataToPass = {
                      message: receivedData.message,
                      days: receivedData.days,
                      theme: receivedData.theme,
                      date: receivedData.date
                    };
                    navigate('/ota2', { state: dataToPass });
                  }
                  ;
                }}
              >
                Editable View

              </text> */}
                <text
                  className="font-montserrat common-pointer ml-10 sm:ml-4 md:ml-6 lg:ml-10 hover:border-b hover:border-b-2 hover:border-b-[#6392F9] hover:text-[#6392F9] p-3"
                  onClick={() => {
                    //  setIsPopup1Open(true);

                    const dataToPass = {
                      location: destination,
                      startDate: startDate,
                      day: day
                    };
                    //clear
                    localStorage.removeItem("INTINERARY_" + itineraryId + "_STARTDATE");
                    localStorage.removeItem("INTINERARY_" + itineraryId + "_DAY");

                    navigate(`/itinerary-save/${creatorId}/${itineraryId}/${itineraryTitle}/editableView`, { state: dataToPass });

                    ;
                  }}
                >
                  Editable View
                </text>
              </div>
            </div>


            {/* start */}
            <div className="w-full" >

              {/* <Img
              src="images/img_rectangle120.png"
              className="h-screen object-cover w-full"
              alt="rectangle120"
              /> */}

              <div className="top-0 left-0 w-full items-center justify-center">

                <div className='font-montserrat  w-[100%]'
                  style={{
                    backgroundColor: isLoading ? 'rgba(216, 216, 216, 0.6)' : 'rgba(216, 216, 216, 0.6)',
                    backgroundImage: isLoading
                      ? 'none' // Set background image to img_rectangle when loading
                      : 'url("/images/img_rectangle120.png")', // Remove background image when not loading
                    // backgroundSize: 'cover', // Adjust as needed
                    // backgroundPosition: 'center', // Adjust as needed
                  }}
                >
                  <div className="items-center justify-center" style={{ backgroundColor: isLoading ? 'rgba(216, 216, 216, 0.6)' : 'rgba(216, 216, 216, 0.6))' }}>

                    {/* satu*/}
                    {!isLoading ? (
                      filteredData.length === 0 ? (
                        // Message when the data array is empty
                        <div className="flex w-full h-[500px] justify-center items-start text-center py-20">
                          <span className="text-gray-500 font-medium py-20">
                            No activities found. <br /> Start adding your activities in the Editable View.
                          </span>
                        </div>
                      ) : (

                        <div className={`grid-cols-1 gap-2 justify-center`}>
                          {filteredData.map((item) => (
                            <div key={item} className="flex items-center justify-center">
                              <div style={{ backgroundColor: '#F5F5F5' }} className="flex items-center justify-center">
                                <div className="w-full px-5">
                                  <div className={`p-3 rounded-lg grid grid-row-2 grid-flow-col w-full md:w-[700px] mt-5`}>
                                    <div className="text-center">
                                      <span className="font-bold block text-[20px]">Day {item.day}</span>
                                      <span style={{ color: '#008009' }} className="font-bold block">
                                        {/* {item.date} */}
                                        {item.activities[0]?.date}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="mt-7 #F5F5F5 w-full md:w-[700px] flex flex-col items-start">
                                    <div className="flex items-center mb-2">
                                      <GiPathDistance color="#008009" size={25} className="rounded-lg w-5 h-5" alt="route" />
                                      <text style={{ color: '#008009', fontWeight: '800', marginLeft: 8 }} className="text-[22px]  ml-2">
                                        Transportation
                                      </text>
                                      <div className="flex" style={{ marginLeft: '30' }}>
                                        <a href={`https://kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_cid=${creatorId}&lc=en&url=%2Fflights`} target="_blank" rel="noopener noreferrer">
                                          <BsAirplane color="#008009" size={25} className="rounded-lg w-5 h-5" alt="route" style={{ marginLeft: '20px', marginRight: '20px' }} />
                                        </a>
                                        <a href={`https://kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_cid=${creatorId}&lc=en&url=%2Fcars`} target="_blank" rel="noopener noreferrer">
                                          <FaCar color="#008009" size={25} className="rounded-lg w-5 h-5" alt="route" style={{ marginLeft: '0px', marginRight: '20px' }} />
                                        </a>
                                        <a href="https://online.ktmb.com.my" target="_blank" rel="noopener noreferrer">

                                          <BsTrainFreightFront color="#008009" size={25} className="rounded-lg w-5 h-5" alt="route" style={{ marginLeft: '0px', marginRight: '20px' }} />
                                        </a>
                                        {baseURL === 'https://vm.epictravel.ai' || 'http://localhost:3000' ? (
                                          <a
                                            // onClick={() => {
                                            //   handleButtonClick(item.date);
                                            // }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <BiBus color="#008009" size={25} className="rounded-lg w-5 h-5" alt="route" />
                                          </a>
                                        ) : (
                                          <a href="https://gohub.com.my" target="_blank" rel="noopener noreferrer">
                                            <BiBus color="#008009" size={25} className="rounded-lg w-5 h-5" alt="route" />
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  </div>


                                  {/* Morning Activities */}
                                  {/* {item.activities.some(activity => activity.morningactivity) && (
                                <> */}
                                  <div className="#F5F5F5 w-full md:w-[700px] flex flex-col items-start">
                                    <div className="flex items-center mb-2">
                                      <BsSun color="#008009" size={25} className="rounded-lg w-5 h-5" alt="route" />
                                      <span style={{ color: '#008009', fontWeight: '800', marginLeft: 8 }} className="text-[22px] ml-2">
                                        Morning
                                      </span>
                                    </div>
                                    <div className="ml-2" style={{ display: 'flex', alignItems: 'center' }}>
                                      <div style={{ marginLeft: '0px' }}>
                                        {/* Content Area with Dotted Border */}
                                        <div
                                          className="flex flex-col pl-4 relative"
                                          style={{
                                            borderLeft: '2px dotted transparent', // Invisible border for spacing
                                            backgroundImage: 'linear-gradient(#008009 50%, transparent 0)',
                                            backgroundSize: '1.5px 10px', // Adjusts size of dots and gap
                                            backgroundRepeat: 'repeat-y',
                                            padding: '8px', // Adjust padding to match the dotted border width
                                          }}
                                        >
                                          <span className='mb-4'></span>

                                          {item.activities.some(activity => activity.morningactivity) ? (
                                            item.activities.filter(activity => activity.morningactivity).map((activity, index) => (
                                              <div key={index} className="pl-5 flex items-start text-[10px] mb-4">
                                                {/* Set a fixed width for the time */}
                                                <span className="text-[10px] font-montserrat" style={{ fontSize: '16px', fontWeight: '550', color: 'black', width: '80px' }}>
                                                  {activity.time}
                                                </span>
                                                <span className="text-[10px] font-montserrat" style={{ fontSize: '16px', fontWeight: '450', color: 'black', width: '5px' }}>
                                                  :
                                                </span>
                                                <div className="flex-1 text-[10px] pl-2">
                                                  <span style={{ fontSize: '16px', fontWeight: '450', color: 'black' }}>
                                                    {activity.morningactivity.replace(activity.morningplace, "")}{' '}
                                                    <span className="text-[#008009] underline" style={{ fontStyle: 'italic' }}>
                                                      <Link
                                                        to={{
                                                          pathname: `/travelplan/blog/display/${creatorId}/${activity.morningplace}/${itineraryId}`,
                                                          state: { itineraryId }
                                                        }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {activity.morningplace}
                                                      </Link>
                                                    </span>
                                                    . {activity.mcomment}

                                                    <div className="mt-2 mb-4" style={{ display: 'flex' }}>
                                                      {activity.morningimage ? (
                                                        <a
                                                          href={getProcessedImageUrl(activity.morningimage)} // Make sure this is a full URL or a valid path
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                        >
                                                          <img
                                                            className="rounded rounded-2xl drop-shadow-lg"
                                                            src={getProcessedImageUrl(activity.morningimage)}
                                                            alt="Display"
                                                            style={{
                                                              width: '200px',
                                                              height: '160px',
                                                              marginTop: 5,
                                                              objectFit: 'cover',
                                                              // border: '1px solid #E0E0E0',
                                                              // borderRadius: '10px',
                                                              background: '#E0E0E0'
                                                            }}
                                                          />
                                                        </a>
                                                      ) : (
                                                        <span></span>
                                                        // <span>No Image Available</span> // Optional: To handle cases where there is no image
                                                      )}
                                                    </div>
                                                  </span>
                                                </div>
                                              </div>
                                            ))
                                          ) : (
                                            <span className="pl-5 text-[14px] italic text-gray-500">No activity</span>
                                          )}

                                          <span className='mb-4'></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* </>
                                )} */}

                                  {/* Lunch Activities */}
                                  {/* <div className="mt-1 #F5F5F5 w-full md:w-[700px] flex flex-col items-start">
                                  <div className="flex items-center mb-2">
                                    <GiKnifeFork color="#008009" size={25} className="rounded-lg w-5 h-5" alt="route" />
                                    <span style={{ color: '#008009', fontWeight: '800', marginLeft: 8 }} className="text-[22px] ml-2">
                                      Lunch
                                    </span>
                                  </div>
                                  <div className="ml-2" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginLeft: '0px' }}>
                                      <div
                                        className="flex flex-col pl-4 relative"
                                        style={{
                                          borderLeft: '2px dotted transparent', // Invisible border for spacing
                                          backgroundImage: 'linear-gradient(#008009 50%, transparent 0)',
                                          backgroundSize: '1.5px 10px', // Adjusts size of dots and gap
                                          backgroundRepeat: 'repeat-y',
                                          padding: '8px', // Adjust padding to match the dotted border width
                                        }}
                                      >
                                        <span className='mb-4'></span>

                                        {item.activities.some(activity => activity.lunch) ? (
                                          item.activities.filter(activity => activity.lunch).map((activity, index) => (
                                            <div key={index} className="pl-5 flex items-start text-[10px] mb-4">
                                              <span className="text-[10px] font-montserrat" style={{ fontSize: '16px', fontWeight: '550', color: 'black', width: '80px' }}>
                                                {activity.time}
                                              </span>
                                              <span className="text-[10px] font-montserrat" style={{ fontSize: '16px', fontWeight: '450', color: 'black', width: '5px' }}>
                                                :
                                              </span>
                                              <div className="flex-1 text-[10px] pl-2">
                                                <span style={{ fontSize: '16px', fontWeight: '450', color: 'black' }}>
                                                  Lunch at {activity.lunch}
                                                </span>
                                                <div className="mt-2 mb-4" style={{ display: 'flex' }}>
                                                  {activity.lunchimage ? (
                                                    <a
                                                      href={getProcessedImageUrl(activity.lunchimage)} // Make sure this is a full URL or a valid path
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      <img
                                                        className="rounded rounded-2xl drop-shadow-lg"
                                                        src={getProcessedImageUrl(activity.lunchimage)}
                                                        alt="Display"
                                                        style={{
                                                          width: '200px',
                                                          height: '160px',
                                                          marginTop: 5,
                                                          objectFit: 'cover',
                                                          // border: '1px solid #E0E0E0',
                                                          // borderRadius: '10px',
                                                          background: '#E0E0E0'
                                                        }}
                                                      />
                                                    </a>
                                                  ) : (
                                                    <span></span>
                                                    // <span>No Image Available</span> // Optional: To handle cases where there is no image
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ))
                                        ) : (
                                          <span className="pl-5 text-[14px] italic text-gray-500">No activity</span>
                                        )}

                                        <span className='mb-4'></span>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}

                                  {/* Afternoon Activities */}
                                  <div className="mt-1 #F5F5F5 w-full md:w-[700px] flex flex-col items-start">
                                    <div className="flex items-center mb-2">
                                      <IoPartlySunnyOutline color="#008009" size={25} className="rounded-lg w-5 h-5" alt="route" />
                                      <span style={{ color: '#008009', fontWeight: '800', marginLeft: 8 }} className="text-[22px] ml-2">
                                        Afternoon
                                      </span>
                                    </div>
                                    <div className="ml-2" style={{ display: 'flex', alignItems: 'center' }}>
                                      <div style={{ marginLeft: '0px' }}>
                                        <div
                                          className="flex flex-col pl-4 relative"
                                          style={{
                                            borderLeft: '2px dotted transparent', // Invisible border for spacing
                                            backgroundImage: 'linear-gradient(#008009 50%, transparent 0)',
                                            backgroundSize: '1.5px 10px', // Adjusts size of dots and gap
                                            backgroundRepeat: 'repeat-y',
                                            padding: '8px', // Adjust padding to match the dotted border width
                                          }}
                                        >
                                          <span className='mb-4'></span>

                                          {item.activities.some(activity => activity.afternoonactivity) ? (
                                            item.activities.filter(activity => activity.afternoonactivity).map((activity, index) => (
                                              <div key={index} className="pl-5 flex items-start text-[10px] mb-4">
                                                {/* Set a fixed width for the time */}
                                                <span className="text-[10px] font-montserrat" style={{ fontSize: '16px', fontWeight: '550', color: 'black', width: '80px' }}>
                                                  {activity.time}
                                                </span>
                                                <span className="text-[10px] font-montserrat" style={{ fontSize: '16px', fontWeight: '450', color: 'black', width: '5px' }}>
                                                  :
                                                </span>
                                                <div className="flex-1 text-[10px] pl-2">
                                                  <span style={{ fontSize: '16px', fontWeight: '450', color: 'black' }}>
                                                    {activity.afternoonactivity.replace(activity.afternoonplace, "")}{' '}
                                                    <span className="text-[#008009] underline">
                                                      <Link
                                                        to={{
                                                          pathname: `/travelplan/blog/display/${creatorId}/${activity.afternoonplace}/${itineraryId}`,
                                                          state: { itineraryId }
                                                        }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {activity.afternoonplace}
                                                      </Link>
                                                    </span>
                                                    . {activity.acomment}

                                                    <div className="mt-2 mb-4" style={{ display: 'flex' }}>
                                                      {activity.afternoonimage ? (
                                                        <a
                                                          href={getProcessedImageUrl(activity.afternoonimage)} // Make sure this is a full URL or a valid path
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                        >
                                                          <img
                                                            className="rounded rounded-2xl drop-shadow-lg"
                                                            src={getProcessedImageUrl(activity.afternoonimage)}
                                                            alt="Display"
                                                            style={{
                                                              width: '200px',
                                                              height: '160px',
                                                              marginTop: 5,
                                                              objectFit: 'cover',
                                                              // border: '1px solid #E0E0E0',
                                                              // borderRadius: '10px',
                                                              background: '#E0E0E0'
                                                            }}
                                                          />
                                                        </a>
                                                      ) : (
                                                        <span></span>
                                                        // <span>No Image Available</span> // Optional: To handle cases where there is no image
                                                      )}
                                                    </div>
                                                  </span>
                                                </div>
                                              </div>
                                            ))
                                          ) : (
                                            <span className="pl-5 text-[14px] italic text-gray-500">No activity</span>
                                          )}

                                          <span className='mb-4'></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                  {/* Evening Activities */}
                                  <div className="mt-1 #F5F5F5 w-full md:w-[700px] flex flex-col items-start">
                                    <div className="flex items-center mb-2">
                                      <BiMoon color="#008009" size={25} className="rounded-lg w-5 h-5" alt="route" />
                                      <span style={{ color: '#008009', fontWeight: '800', marginLeft: 8 }} className="text-[22px] ml-2">
                                        Evening
                                      </span>
                                    </div>
                                    <div className="ml-2" style={{ display: 'flex', alignItems: 'center' }}>
                                      <div style={{ marginLeft: '0px' }}>
                                        <div
                                          className="flex flex-col pl-4 relative"
                                          style={{
                                            borderLeft: '2px dotted transparent', // Invisible border for spacing
                                            backgroundImage: 'linear-gradient(#008009 50%, transparent 0)',
                                            backgroundSize: '1.5px 10px', // Adjusts size of dots and gap
                                            backgroundRepeat: 'repeat-y',
                                            padding: '8px', // Adjust padding to match the dotted border width
                                          }}
                                        >
                                          <span className='mb-4'></span>

                                          {item.activities.some(activity => activity.eveningactivity) ? (
                                            item.activities.filter(activity => activity.eveningactivity).map((activity, index) => (
                                              <div key={index} className="pl-5 flex items-start text-[10px] mb-4">
                                                {/* Set a fixed width for the time */}
                                                <span className="text-[10px] font-montserrat" style={{ fontSize: '16px', fontWeight: '550', color: 'black', width: '80px' }}>
                                                  {activity.time}
                                                </span>
                                                <span className="text-[10px] font-montserrat" style={{ fontSize: '16px', fontWeight: '450', color: 'black', width: '5px' }}>
                                                  :
                                                </span>
                                                <div className="flex-1 text-[10px] pl-2">
                                                  <span style={{ fontSize: '16px', fontWeight: '450', color: 'black' }}>
                                                    {activity.eveningactivity.replace(activity.eveningplace, "")}{' '}
                                                    <span className="text-[#008009] underline">
                                                      <Link
                                                        to={{
                                                          pathname: `/travelplan/blog/display/${creatorId}/${activity.eveningplace}/${itineraryId}`,
                                                          state: { itineraryId }
                                                        }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {activity.eveningplace}
                                                      </Link>
                                                    </span>
                                                    . {activity.ecomment}

                                                    <div className="mt-2 mb-4" style={{ display: 'flex' }}>
                                                      {activity.eveningimage ? (
                                                        <a
                                                          href={getProcessedImageUrl(activity.eveningimage)} // Make sure this is a full URL or a valid path
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                        >
                                                          <img
                                                            className="rounded rounded-2xl drop-shadow-lg"
                                                            src={getProcessedImageUrl(activity.eveningimage)}
                                                            alt="Display"
                                                            style={{
                                                              width: '200px',
                                                              height: '160px',
                                                              marginTop: 5,
                                                              objectFit: 'cover',
                                                              // border: '1px solid #E0E0E0',
                                                              // borderRadius: '10px',
                                                              background: '#E0E0E0'
                                                            }}
                                                          />
                                                        </a>
                                                      ) : (
                                                        <span></span>
                                                        // <span>No Image Available</span> // Optional: To handle cases where there is no image
                                                      )}
                                                    </div>
                                                  </span>
                                                </div>
                                              </div>
                                            ))
                                          ) : (
                                            <span className="pl-5 text-[14px] italic text-gray-500">No activity</span>
                                          )}

                                          <span className='mb-4'></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Dinner Activities */}
                                  {/* <div className="mt-1 #F5F5F5 w-full md:w-[700px] flex flex-col items-start">
                                  <div className="flex items-center mb-2">
                                    <GiKnifeFork color="#008009" size={25} className="rounded-lg w-5 h-5" alt="route" />
                                    <span style={{ color: '#008009', fontWeight: '800', marginLeft: 8 }} className="text-[22px] ml-2">
                                      Dinner
                                    </span>
                                  </div>
                                  <div className="ml-2" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginLeft: '0px' }}>
                                      <div
                                        className="flex flex-col pl-4 relative"
                                        style={{
                                          borderLeft: '2px dotted transparent', // Invisible border for spacing
                                          backgroundImage: 'linear-gradient(#008009 50%, transparent 0)',
                                          backgroundSize: '1.5px 10px', // Adjusts size of dots and gap
                                          backgroundRepeat: 'repeat-y',
                                          padding: '8px', // Adjust padding to match the dotted border width
                                        }}
                                      >
                                        <span className='mb-4'></span>

                                        {item.activities.some(activity => activity.dinner) ? (
                                          item.activities.filter(activity => activity.dinner).map((activity, index) => (
                                            <div key={index} className="pl-5 flex items-start text-[10px] mb-4">
                                              <span className="text-[10px] font-montserrat" style={{ fontSize: '16px', fontWeight: '550', color: 'black', width: '80px' }}>
                                                {activity.time}
                                              </span>
                                              <span className="text-[10px] font-montserrat" style={{ fontSize: '16px', fontWeight: '450', color: 'black', width: '5px' }}>
                                                :
                                              </span>
                                              <div className="flex-1 text-[10px] pl-2">
                                                <span style={{ fontSize: '16px', fontWeight: '450', color: 'black' }}>
                                                  Dinner at {activity.dinner}
                                                </span>
                                                <div className="mt-2 mb-4" style={{ display: 'flex' }}>
                                                      {activity.dinnerimage ? (
                                                        <a
                                                          href={getProcessedImageUrl(activity.dinnerimage)} 
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                        >
                                                          <img
                                                            className="rounded rounded-2xl drop-shadow-lg"
                                                            src={getProcessedImageUrl(activity.dinnerimage)} 
                                                            alt="Display"
                                                            style={{
                                                              width: '200px',
                                                              height: '160px',
                                                              marginTop: 5,
                                                              objectFit: 'cover', 
                                                              // border: '1px solid #E0E0E0',
                                                              // borderRadius: '10px',
                                                              background: '#E0E0E0'
                                                            }}
                                                          />

                                                        </a>
                                                      ) : (
                                                        <span></span>
                                                        // <span>No Image Available</span> // Optional: To handle cases where there is no image
                                                      )}
                                                    </div>
                                              </div>
                                            </div>
                                          ))
                                        ) : (
                                          <span className="pl-5 text-[14px] italic text-gray-500">No activity</span>
                                        )}

                                        <span className='mb-8'></span>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}


                                  {/* Bedtime  */}
                                  <div className="mt-1 w-full md:w-[700px] flex flex-col items-start mb-10">
                                    {(() => {
                                      // Get the current date
                                      const currentDate = new Date(receivedData.date);
                                      const updatedDate = new Date(currentDate.getTime());
                                      const updatedDate1 = new Date(currentDate.getTime());
                                      updatedDate.setDate(updatedDate.getDate() + item.day - 1);

                                      // Format the date as "YYYY-MM-DD"
                                      const year = updatedDate1.getFullYear();
                                      const month = String(updatedDate1.getMonth() + 1).padStart(2, '0');
                                      const day = String(updatedDate1.getDate()).padStart(2, '0');

                                      const year1 = updatedDate.getFullYear();
                                      const month1 = String(updatedDate.getMonth() + 1).padStart(2, '0');
                                      const day1 = String(updatedDate.getDate()).padStart(2, '0');

                                      // const formattedDate1 = `${year}-${month}-${day}`;
                                      // const formattedDate2 = `${year1}-${month1}-${day1}`;

                                      const formattedDate1 = formatDate1(receivedData.date, item.day);
                                      const formattedDate2 = getNextDayDate(formatDate1(receivedData.date, item.day));

                                      return (
                                        // <span style={{ color: '#008009' }} className="font-bold block font-semibold justify-center font-montserrat sm:text-[6px] md:text-[30px] lg:text-[6px]">
                                        //   &nbsp;&nbsp; {formattedDate}
                                        // </span>

                                        <div className="flex items-center mb-10">
                                          <GiBed
                                            color="#008009"
                                            size={25}
                                            className="rounded-lg w-5 h-5" // Set fixed width and height
                                            alt="route"
                                          />
                                          <text style={{ color: '#008009', ontWeight: '600' }} className="text-[22px]  ml-2 font-montserrat">
                                            Bedtime-&nbsp;&nbsp;
                                            <text style={{ color: '#008009', ontWeight: '300' }} className=' text-[18px] '>
                                              Hotels Deals in&nbsp;
                                            </text>
                                            <text style={{ color: '#008009', ontWeight: '300' }} className=' text-[18px] underline'>
                                              {/* <a
                                            href={`http://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/${receivedData.message}/${formattedDate1}/${formattedDate2}?sort=distance_a`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {receivedData.message}
                                          </a> */}

                                              {
                                                (() => {
                                                  // Logic to get formattedDate1 and formattedDate2                                       

                                                  // Prepare the URL based on the condition
                                                  let urlmain;
                                                  if (receivedData.message === 'Forest City') {
                                                    // urlmain = `https://kayak.com.my/in?a=kan_262812_573418&lc=en&url=%2Fhotels/Forest-City-Marina-Hotel,Gelang-Patah,Malaysia-p2664154-h2767708/${formattedDate1}/${formattedDate3}?sort=distance_a`;
                                                    urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Forest-City-Marina-Hotel,Gelang-Patah,Malaysia-p2664154-h2767708/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                  }
                                                  else if (receivedData.message === 'Perak') {
                                                    urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Perak,Malaysia-p21085/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                  }
                                                  else if (receivedData.message === 'Sabah') {
                                                    urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Sabah,Malaysia-p21083/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                  }
                                                  else if (receivedData.message === 'Pahang') {
                                                    urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Pahang,Malaysia-p21086/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                  }
                                                  else if (receivedData.message === 'Penang') {
                                                    urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Penang,Malaysia-p247880/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                  }
                                                  else if (receivedData.message === 'Sarawak') {
                                                    urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Sarawak,Malaysia-p21082/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                  }
                                                  else if (receivedData.message === 'Kedah') {
                                                    urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Kedah,Malaysia-p21092/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                  }
                                                  else if (receivedData.message === 'Selangor') {
                                                    urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Selangor,Malaysia-p21081/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                  }
                                                  else if (receivedData.message === 'Negeri Sembilan') {
                                                    urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Negeri-Sembilan,Malaysia-p21087/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                  }
                                                  else if (receivedData.message === 'Kelantan') {
                                                    urlmain = `https://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/Kelantan-p21088/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                  }
                                                  else {
                                                    let encodedCityName = encodeURIComponent(receivedData.message);
                                                    urlmain = `http://kayak.com.my/in?a=kan_262812_573418&enc_cid=${creatorId}&lc=en&url=%2Fhotels/${encodedCityName}/${formattedDate1}/${formattedDate2}?sort=distance_a`;
                                                  }

                                                  // Log the URL being navigated to
                                                  console.log(`Navigating to web: ${urlmain}`);

                                                  return (
                                                    <a
                                                      href={urlmain}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {receivedData.message}
                                                    </a>
                                                  );
                                                })()
                                              }
                                            </text>
                                          </text>
                                        </div>
                                      );
                                    })()}
                                  </div>

                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )
                    ) : (

                      <div className="h-[100%] w-[100%] mt-30 flex items-center justify-center flex-col py-5"  >
                        <span style={{ fontSize: '20px', fontWeight: 'normal' }} className="mb-5">{text}</span>
                        <CircularProgress size={20} sx={{ color: 'black' }} />
                      </div>

                    )}

                  </div>
                </div>
              </div>

              {isLoading && (
                <Img
                  src="/images/img_rectangle120.png"
                  className="h-screen object-cover w-full"
                  alt="rectangle120"
                />
              )}

            </div>

            {/* end */}

          </div>


          {!isLoading || !filteredData?.length && (
            <div className="h-[100%] w-[100%] flex items-center justify-center flex-col" style={{ backgroundColor: 'white', marginTop: '0px', padding: '20px' }}>
              {/* <span className="text-center mb-4" style={{ fontSize: '30px', fontWeight: 'bold',marginTop: '300px' }}>
                Oops, looks like the server is experiencing high demand. Please try again.
              </span>
              <Button
                className="flex items-center justify-center rounded-[10px] px-3 py-2"
                style={{ backgroundColor: '#00a19a', width: '320px', height: '60px' }}
                size="smIcn"
                onClick={handleNavigate689}
              >
                <text style={{ fontSize: '20px', fontWeight: 'bold' }} className="uppercase font-montserrat text-[25px] text-white">Regenerate itinerary</text>
              </Button> */}
            </div>
          )}

        </div>

      </div >

    </>
  );
};

export default OverviewTP;
