// actionTypes.js
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';
export const FETCH_DATA_LOADING = 'FETCH_DATA_LOADING';
export const SAVE_ITINERARY_ID = 'SAVE_ITINERARY_ID';

export const ADD_RESTAURANT_REQUEST = 'ADD_RESTAURANT_REQUEST';
export const ADD_RESTAURANT_SUCCESS = 'ADD_RESTAURANT_SUCCESS';
export const ADD_RESTAURANT_FAILURE = 'ADD_RESTAURANT_FAILURE';

