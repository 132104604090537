export const MemberData = [
    {
      name: "Giacamo Guilizzoni",
      email: "test@gmail.com",
      displayname: "Giacamo ",
      dob: "15/10/1978",
      phoneno: "0123456789",
      gender: "M",
      registerdate: "11-03-2024",
      status: "Active",
      role: "Admin",
    },
    {
        name: "Marco Botton",
        email: "test@gmail.com",
        displayname: "Marco ",
        dob: "11/2/1990",
        phoneno: "0123456789",
        gender: "M",
        registerdate: "11-03-2024",
        status: "Active",
        role: "Admin",
      },
      {
        name: "Mariah Maclachlan",
        email: "test@gmail.com",
        displayname: "Mariah ",
        dob: "15/10/1978",
        phoneno: "30/12/1997",
        gender: "F",
        registerdate: "11-03-2024",
        status: "Active",
        role: "Admin",
      },
      {
        name: "Valerie Liberty",
        email: "test@gmail.com",
        displayname: "Valerie ",
        dob: "26/12/1992",
        phoneno: "0123456789",
        gender: "F",
        registerdate: "11-03-2024",
        status: "Active",
        role: "Admin",
      },
]