import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import HeaderAdmin from 'components/Header/HeaderAdmin/index';
import './App.css';
import { MdOutlineRocket, MdWarning } from "react-icons/md";
import { BsPeople } from "react-icons/bs";
import { IoMdSearch } from "react-icons/io";
import { BsFillTrash3Fill } from "react-icons/bs";
import { MemberData } from "./data";



const MemberManagementPage = () => {

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
    };

    const [isPopupOpen2, setIsPopupOpen2] = useState(false);

    const togglePopup2 = () => {
      setIsPopupOpen2(!isPopupOpen2);
    };

    const navigate = useNavigate();

    function handleNavigate1() {
        navigate("/admin-dashboard");
    }
    

    return (
        <>
            <div className="bg-[#F5F5F5] min-h-screen">
                <HeaderAdmin className="fixed invisible lg:visible" />
                <div className="flex min-h-screen items-start">
                    <div className="bg-gradient-to-b from-[#00A19A] to-[#2A3075] min-h-screen p-[15px]">
                        <div className="p-[10px] flex w-[250px] cursor-pointer" onClick={handleNavigate1}>
                            <MdOutlineRocket size={35} className="text-[#FFFFFF]"/>
                            <div className="ml-[20px]">
                                <text className="font-montserrat font-semibold text-sm text-[#FFFFFF]">
                                    Dashboard
                                </text>
                            </div>
                        </div>
                        <div className="bg-[#FFFFFF] p-[10px] bg-opacity-30 flex w-[250px] cursor-pointer">
                            <BsPeople size={35} className="text-[#FFFFFF]"/>
                            <div className="ml-[20px]">
                                <text className="font-montserrat font-semibold text-sm text-[#FFFFFF]">
                                    Member Management
                                </text>
                            </div>
                        </div>
                    </div>
                    <div className="p-[30px] w-full min-h-screen">
                        <div className="flex">
                            <BsPeople size={40} className="text-[#2A3075]"/>
                            <div className="ml-[20px]">
                                <text className="font-montserrat font-semibold text-sm text-[#2A3075]">
                                    Member Management
                                </text>
                            </div>
                        </div>
                        <div className="flex justify-between pt-[40px] w-full gap-2">
                            <div className="w-full border-[#AEAEAE] border-[0.5px] bg-[#FFFFFF] p-[10px] rounded-[15px] shadow-sm flex">
                                <IoMdSearch size={25} className="text-[#747373]"/>
                                <input type="text" className="border-none p-0 bg-transparent ml-5 font-montserrat text-sm placeholder:text-[#AEAEAE] text-[#747373]" 
                                placeholder="Search by Name"/>
                            </div>
                        </div>

                        <div className="pt-[20px]">
                            <div className="flex gap-2 p-2">
                                <div className="w-[150px]">
                                    <text className="text-sm text-[#747373] font-montserrat">
                                        Name
                                    </text>
                                </div>
                                <div className="w-[130px]">
                                    <text className="text-sm text-[#747373] font-montserrat">
                                        Email
                                    </text>
                                </div>
                                <div className="w-[110px]">
                                    <text className="text-sm text-[#747373] font-montserrat">
                                        Display Name
                                    </text>
                                </div>
                                <div className="w-[110px]">
                                    <text className="text-sm text-[#747373] font-montserrat">
                                        Date of Birth
                                    </text>
                                </div>
                                <div className="w-[90px]">
                                    <text className="text-sm text-[#747373] font-montserrat">
                                        Phone No.
                                    </text>
                                </div>
                                <div className="w-[70px] flex justify-center">
                                    <text className="text-sm text-[#747373] font-montserrat">
                                        Gender
                                    </text>
                                </div>
                                <div className="w-[100px]">
                                    <text className="text-sm text-[#747373] font-montserrat">
                                        Register Date
                                    </text>
                                </div>
                                <div className="w-[70px] flex justify-center">
                                    <text className="text-sm text-[#747373] font-montserrat">
                                        Role
                                    </text>
                                </div>
                                <div className="w-[80px] flex justify-center">
                                    <text className="text-sm text-[#747373] font-montserrat">
                                        Status
                                    </text>
                                </div>
                                <div className="w-[85px] flex justify-center">
                                    <text className="text-sm text-[#747373] font-montserrat">
                                        Edit Profile
                                    </text>
                                </div>

                                <div className="w-[95px] flex justify-center">
                                    <text className="text-sm text-[#747373] font-montserrat">
                                        Delete User
                                    </text>
                                </div>
                            </div>
                            {MemberData.map((data, index) => (
                                <div className="flex gap-2 bg-[#FFFFFF] p-2 mt-2">
                                    <div className="w-[150px]">
                                        <text className="text-sm text-[#747373] font-montserrat">
                                            {data.name}
                                        </text>
                                    </div>
                                    <div className="w-[130px]">
                                        <text className="text-sm text-[#747373] font-montserrat">
                                            {data.email}
                                        </text>
                                    </div>
                                    <div className="w-[110px]">
                                        <text className="text-sm text-[#747373] font-montserrat">
                                            {data.displayname}
                                        </text>
                                    </div>
                                    <div className="w-[110px]">
                                        <text className="text-sm text-[#747373] font-montserrat">
                                            {data.dob}
                                        </text>
                                    </div>
                                    <div className="w-[90px]">
                                        <text className="text-sm text-[#747373] font-montserrat">
                                            {data.phoneno}
                                        </text>
                                    </div>
                                    <div className="w-[70px] flex justify-center">
                                        <text className="text-sm text-[#747373] font-montserrat">
                                            {data.gender}
                                        </text>
                                    </div>
                                    <div className="w-[100px]">
                                        <text className="text-sm text-[#747373] font-montserrat">
                                            {data.registerdate}
                                        </text>
                                    </div>
                                    <div className="w-[70px] flex justify-center">
                                        <text className="text-sm text-[#747373] font-montserrat">
                                            {data.role}
                                        </text>
                                    </div>
                                    <div className="w-[80px] flex justify-center">
                                        <text className="text-sm text-[#747373] font-montserrat">
                                            {data.status}
                                        </text>
                                    </div>
                                    <div className="w-[85px] flex justify-center">
                                        <div onClick={togglePopup} className="common-pointer px-[10px] py-[5px] bg-[#FFFFFF] hover:bg-[#AEAEAE] border-[0.5px] border-[#AEAEAE] rounded-[5px] w-fit">
                                            <text className="text-sm text-[#747373] font-montserrat">
                                                Edit
                                            </text>
                                        </div>
                                    </div>

                                    <div className="w-[95px] flex justify-center">
                                        <div onClick={togglePopup2} className="common-pointer px-[10px] py-[5px] bg-[#FFFFFF] hover:bg-[#AEAEAE] border-[0.5px] border-[#AEAEAE] rounded-[5px] w-fit">
                                            <BsFillTrash3Fill size={20} className="text-[#747373]"/>
                                        </div>
                                    </div>
                                </div>
                            )) }

                        </div>
                        
                    </div>

                </div>

                {isPopupOpen && (
                    <div className="popup overflow-y-auto">
                        <div className="popup-inner w-full">
                            <div>
                                <text className="text-lg font-montserrat font-semibold">
                                    Edit Member Profile
                                </text>
                            </div>
                            <div className="flex mt-[30px] w-full">
                                <div className="w-[200px]">
                                    <text className="text-sm font-montserrat font-medium">
                                        Name
                                    </text>
                                </div>
                                <div className="bg-[#F9FAFB] border-[0.5px] border-[#AEAEAE] w-full flex p-[12px] rounded-[5px]">
                                    <input type="text" className="border-none p-0 bg-transparent w-[700px] font-montserrat text-sm placeholder:text-[#AEAEAE] text-[#747373]"/>
                                </div>
                            </div>
                            <div className="flex mt-[20px] w-full">
                                <div className="w-[200px]">
                                    <text className="text-sm font-montserrat font-medium">
                                        Email
                                    </text>
                                </div>
                                <div className="bg-[#F9FAFB] border-[0.5px] border-[#AEAEAE] w-full flex p-[12px] rounded-[5px]">
                                    <input type="text" className="border-none p-0 bg-transparent w-[700px] font-montserrat text-sm placeholder:text-[#AEAEAE] text-[#747373]"/>
                                </div>
                            </div>
                            <div className="flex mt-[20px] w-full">
                                <div className="w-[200px]">
                                    <text className="text-sm font-montserrat font-medium">
                                        Display Name
                                    </text>
                                </div>
                                <div className="bg-[#F9FAFB] border-[0.5px] border-[#AEAEAE] w-full flex p-[12px] rounded-[5px]">
                                    <input type="text" className="border-none p-0 bg-transparent w-[700px] font-montserrat text-sm placeholder:text-[#AEAEAE] text-[#747373]"/>
                                </div>
                            </div>
                            <div className="flex mt-[20px] w-full">
                                <div className="w-[200px]">
                                    <text className="text-sm font-montserrat font-medium">
                                        Date of Birth
                                    </text>
                                </div>
                                <div className="bg-[#F9FAFB] border-[0.5px] border-[#AEAEAE] w-full flex p-[12px] rounded-[5px]">
                                    <input type="date" className="border-none p-0 bg-transparent w-[700px] font-montserrat text-sm placeholder:text-[#AEAEAE] text-[#747373]"/>
                                </div>
                            </div>
                            <div className="flex mt-[20px] w-full">
                                <div className="w-[200px]">
                                    <text className="text-sm font-montserrat font-medium">
                                        Phone No.
                                    </text>
                                </div>
                                <div className="bg-[#F9FAFB] border-[0.5px] border-[#AEAEAE] w-full flex p-[12px] rounded-[5px]">
                                    <input type="number" className="border-none p-0 bg-transparent w-[700px] font-montserrat text-sm placeholder:text-[#AEAEAE] text-[#747373]"/>
                                </div>
                            </div>
                            <div className="flex mt-[20px] w-full">
                                <div className="w-[200px]">
                                    <text className="text-sm font-montserrat font-medium">
                                        Gender
                                    </text>
                                </div>
                                <select id="Gender" class="bg-[#F9FAFB] border-[0.5px] border-[#AEAEAE] w-full flex p-[12px] rounded-[5px] text-sm font-montserrat font-medium">
                                    <option selected></option>
                                    <option value="F">Female</option>
                                    <option value="M">Male</option>
                                </select>
                            </div>
                            <div className="flex mt-[20px] w-full">
                                <div className="w-[200px]">
                                    <text className="text-sm font-montserrat font-medium">
                                        Register Date
                                    </text>
                                </div>
                                <div className="bg-[#F9FAFB] border-[0.5px] border-[#AEAEAE] w-full flex p-[12px] rounded-[5px]">
                                    <input type="date" className="border-none p-0 bg-transparent w-[700px] font-montserrat text-sm placeholder:text-[#AEAEAE] text-[#747373]"/>
                                </div>
                            </div>
                            <div className="flex mt-[20px] w-full">
                                <div className="w-[200px]">
                                    <text className="text-sm font-montserrat font-medium">
                                        Status
                                    </text>
                                </div>
                                <select id="Status" class="bg-[#F9FAFB] border-[0.5px] border-[#AEAEAE] w-full flex p-[12px] rounded-[5px] text-sm font-montserrat font-medium">
                                    <option selected></option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                    <option value="blacklisted">Blacklisted</option>
                                </select>
                            </div>
                            <div className="flex mt-[20px] w-full">
                                <div className="w-[200px]">
                                    <text className="text-sm font-montserrat font-medium">
                                        Role
                                    </text>
                                </div>
                                <select id="Role" class="bg-[#F9FAFB] border-[0.5px] border-[#AEAEAE] w-full flex p-[12px] rounded-[5px] text-sm font-montserrat font-medium">
                                    <option selected></option>
                                    <option value="admin">Admin</option>
                                </select>
                            </div>

                            {/* Footer */}
                            <div className="mt-[70px] w-full justify-between flex gap-4">
                                <div className="flex justify-center bg-[#2A3075] p-[10px] rounded-[5px] w-full common-pointer">
                                    <text className="text-[#FFFFFF] text-md font-medium font-montserrat">
                                        Save
                                    </text>
                                </div>
                                <div onClick={togglePopup} className="flex justify-center bg-[#747373] p-[10px] rounded-[5px] w-full common-pointer">
                                    <text className="text-[#FFFFFF] text-md font-medium font-montserrat">
                                        Cancel
                                    </text>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {isPopupOpen2 && (
                    <div className="popup">
                        <div className="popup-inner-2">
                            <div className="w-full bg-[#ED4C5C] flex justify-center p-[10px] rounded-t-[10px]">
                                <MdWarning size={170} className="text-[#FFFFFF]"/>
                            </div>

                            <div className="pt-[50px] flex justify-center w-full">
                                <text className="text-2xl font-montserrat font-semibold text-[#000000]">
                                    WARNING
                                </text>
                            </div>

                            <div className="pt-[20px] flex justify-center w-full">
                                <text className="text-lg font-montserrat font-semibold text-[#000000] text-center">
                                    Are you sure you want to remove this user? <br/> This action cannot be undone
                                </text>
                            </div>
                            

                            {/* Footer */}
                            <div className=" w-full justify-between flex gap-4 p-[50px]">
                                <div className="flex justify-center bg-[#ED4C5C] p-[10px] rounded-[5px] w-full common-pointer">
                                    <text className="text-[#FFFFFF] text-md font-medium font-montserrat">
                                        Yes
                                    </text>
                                </div>
                                <div onClick={togglePopup2} className="flex justify-center bg-[#747373] p-[10px] rounded-[5px] w-full common-pointer">
                                    <text className="text-[#FFFFFF] text-md font-medium font-montserrat">
                                        Cancel
                                    </text>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                

            </div>

        </>
    );

};

export default MemberManagementPage;