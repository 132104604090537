import { Row } from "components/Row/index";
import { IoAdd } from "react-icons/io5";
import AddtoContentNew from "components/Addto/AddtoContentNew/index";
import { useAuth } from "AuthContext";
import React, { useState, useEffect, useLayoutEffect } from 'react';


const AddtoBtn2 = ({ openPopup33, openPopup44, activityId, setActivityId, openPopup1 }) => {
  const { isLoggedIn } = useAuth();

  const handleClick = () => {
    if (isLoggedIn) {

      // User is logged in, perform the current activity
      console.log("Button clicked with activityId:", activityId);
      setActivityId(activityId);
      openPopup44();
    } else {
      // User is not logged in, show the login popup
      openPopup1();

    }
  };

  return (
    <>
      <div>
        <Row className="common-pointer items-center font-normal" onClick={handleClick}
        >
          <button className="rounded-full bg-[#e6e5e5] lg:w-[20px] lg:h-[20px] xs:w-[40px] xs:h-[40px] ">
            <IoAdd className="text-center justify-center lg:h-[20px] lg:w-[20px] xs:h-[40px] xs:w-[40px] text-[#00A19A] font-medium" />
          </button>
          <text className="lg:text-[14px] md:text-[28px] ml-2 mt-0.5">Add to</text>
        </Row>
      </div>
    </>
  );
};

export default AddtoBtn2;
