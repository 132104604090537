import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import HeaderAdmin from 'components/Header/HeaderAdmin/index';
import { MdOutlineRocket } from "react-icons/md";
import { BsPeople } from "react-icons/bs";
import { FaUserPlus, FaUserCheck } from "react-icons/fa";



const DashboardAdminPage = () => {
    const navigate = useNavigate();

    function handleNavigate1() {
        navigate("/admin-member-management");
    }
    

    return (
        <>
            <div className="bg-[#F5F5F5] min-h-screen">
                <HeaderAdmin className="fixed invisible lg:visible" />
                <div className="flex min-h-screen items-start">
                    <div className="bg-gradient-to-b from-[#00A19A] to-[#2A3075] min-h-screen p-[15px]">
                        <div className="bg-[#FFFFFF] p-[10px] bg-opacity-30 flex w-[250px] cursor-pointer">
                            <MdOutlineRocket size={35} className="text-[#FFFFFF]"/>
                            <div className="ml-[20px]">
                                <text className="font-montserrat font-semibold text-sm text-[#FFFFFF]">
                                    Dashboard
                                </text>
                            </div>
                        </div>
                        <div className="p-[10px] flex w-[250px] cursor-pointer" onClick={handleNavigate1}>
                            <BsPeople size={35} className="text-[#FFFFFF]"/>
                            <div className="ml-[20px]">
                                <text className="font-montserrat font-semibold text-sm text-[#FFFFFF]">
                                    Member Management
                                </text>
                            </div>
                        </div>
                    </div>
                    <div className="p-[30px] w-full min-h-screen">
                        <div className="flex">
                            <MdOutlineRocket size={35} className="text-[#2A3075]"/>
                            <div className="ml-[20px]">
                                <text className="font-montserrat font-semibold text-sm text-[#2A3075]">
                                    Dashboard
                                </text>
                            </div>
                        </div>

                        <div className="flex justify-between pt-[40px] w-full gap-2">
                            <div className="flex shadow-md py-[30px] px-[40px] bg-[#FFFFFF] border-[0.5px] border-[#AEAEAE] rounded-[10px] w-full">
                                <div className="rounded-full p-[20px] bg-[#00A19A] flex justify-center">
                                    <FaUserPlus size={55} className="text-[#FFFFFF]"/>
                                </div>
                                <div className="ml-[40px]">
                                    <div>
                                        <text className="text-[#00A19A] text-xl font-montserrat font-semibold">
                                            Total Registered User
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#1E1E1E] text-sm font-montserrat font-medium">
                                            21
                                        </text>
                                    </div>
                                </div>
                            </div>
                            <div className="flex shadow-md py-[30px] px-[40px] bg-[#FFFFFF] border-[0.5px] border-[#AEAEAE] rounded-[10px] w-full">
                                <div className="rounded-full p-[20px] bg-[#00A19A] flex justify-center">
                                    <FaUserCheck size={55} className="text-[#FFFFFF]"/>
                                </div>
                                <div className="ml-[40px]">
                                    <div>
                                        <text className="text-[#00A19A] text-xl font-montserrat font-semibold">
                                            Total Active User
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#1E1E1E] text-sm font-montserrat font-medium">
                                            16
                                        </text>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between pt-[10px] w-full gap-2">
                            <div className="flex shadow-md py-[30px] px-[40px] bg-[#FFFFFF] border-[0.5px] border-[#AEAEAE] rounded-[10px] w-full">
                                <div className="rounded-full p-[20px] bg-[#00A19A] flex justify-center">
                                    <FaUserPlus size={50} className="text-[#FFFFFF]"/>
                                </div>
                                <div className="ml-[40px]">
                                    <div>
                                        <text className="text-[#00A19A] text-lg font-montserrat font-semibold">
                                            Total Registered
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#00A19A] text-sm font-montserrat font-semibold">
                                            (This Week)
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#1E1E1E] text-sm font-montserrat font-medium">
                                            7
                                        </text>
                                    </div>
                                </div>
                            </div>
                            <div className="flex shadow-md py-[30px] px-[40px] bg-[#FFFFFF] border-[0.5px] border-[#AEAEAE] rounded-[10px] w-full">
                                <div className="rounded-full p-[20px] bg-[#00A19A] flex justify-center">
                                    <FaUserPlus size={50} className="text-[#FFFFFF]"/>
                                </div>
                                <div className="ml-[40px]">
                                    <div>
                                        <text className="text-[#00A19A] text-lg font-montserrat font-semibold">
                                            Total Registered
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#00A19A] text-sm font-montserrat font-semibold">
                                            (This Month)
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#1E1E1E] text-sm font-montserrat font-medium">
                                            14
                                        </text>
                                    </div>
                                </div>
                            </div>
                            <div className="flex shadow-md py-[30px] px-[40px] bg-[#FFFFFF] border-[0.5px] border-[#AEAEAE] rounded-[10px] w-full">
                                <div className="rounded-full p-[20px] bg-[#00A19A] flex justify-center">
                                    <FaUserPlus size={50} className="text-[#FFFFFF]"/>
                                </div>
                                <div className="ml-[40px]">
                                    <div>
                                        <text className="text-[#00A19A] text-lg font-montserrat font-semibold">
                                            Total Registered
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#00A19A] text-sm font-montserrat font-semibold">
                                            (This Year)
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#1E1E1E] text-sm font-montserrat font-medium">
                                            21
                                        </text>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between pt-[10px] w-full gap-2">
                            <div className="flex shadow-md py-[30px] px-[40px] bg-[#FFFFFF] border-[0.5px] border-[#AEAEAE] rounded-[10px] w-full">
                                <div className="rounded-full p-[20px] bg-[#00A19A] flex justify-center">
                                    <FaUserCheck size={50} className="text-[#FFFFFF]"/>
                                </div>
                                <div className="ml-[40px]">
                                    <div>
                                        <text className="text-[#00A19A] text-lg font-montserrat font-semibold">
                                            Total Active
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#00A19A] text-sm font-montserrat font-semibold">
                                            (This Week)
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#1E1E1E] text-sm font-montserrat font-medium">
                                            2
                                        </text>
                                    </div>
                                </div>
                            </div>
                            <div className="flex shadow-md py-[30px] px-[40px] bg-[#FFFFFF] border-[0.5px] border-[#AEAEAE] rounded-[10px] w-full">
                                <div className="rounded-full p-[20px] bg-[#00A19A] flex justify-center">
                                    <FaUserCheck size={50} className="text-[#FFFFFF]"/>
                                </div>
                                <div className="ml-[40px]">
                                    <div>
                                        <text className="text-[#00A19A] text-lg font-montserrat font-semibold">
                                            Total Active
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#00A19A] text-sm font-montserrat font-semibold">
                                            (This Month)
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#1E1E1E] text-sm font-montserrat font-medium">
                                            9
                                        </text>
                                    </div>
                                </div>
                            </div>
                            <div className="flex shadow-md py-[30px] px-[40px] bg-[#FFFFFF] border-[0.5px] border-[#AEAEAE] rounded-[10px] w-full">
                                <div className="rounded-full p-[20px] bg-[#00A19A] flex justify-center">
                                    <FaUserCheck size={50} className="text-[#FFFFFF]"/>
                                </div>
                                <div className="ml-[40px]">
                                    <div>
                                        <text className="text-[#00A19A] text-lg font-montserrat font-semibold">
                                            Total Active
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#00A19A] text-sm font-montserrat font-semibold">
                                            (This Year)
                                        </text>
                                    </div>
                                    <div>
                                        <text className="text-[#1E1E1E] text-sm font-montserrat font-medium">
                                            16
                                        </text>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>

                

            </div>

        </>
    );

};

export default DashboardAdminPage;