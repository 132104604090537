export const foodData = [
    {
      id: "1",
      title: "TROVE Johor Bahru ",
      location: "Johor Bahru City Center, Johor Bahru",
      rating: "4.3",
      image: "https://pix8.agoda.net/hotelImages/4861656/-1/db07c2744c9aa9deb0f7a144aa3c1e44.png?ca=9&ce=1&s=1024x768",
      alt: "Trove",  
      tags: ["Breakfast included", "Early check-in"],
      price: "$ 45",
      review: "1,293",
      star: "4",
      url: "hotelvm"
    },
    {
        id: "2",
        title: "Grand Medini",
        location: "Gelang Patah/Legoland, Johor Bahru",
        rating: "4.1",
        image: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/446397790.jpg?k=e3b9af5a66997e5eea2f647635357c7d950bf37de698253233bd35df75785df6&o=&hp=1",
        alt: "medini",  
        tags: ["Breakfast included"],
        price: "$ 99",
        review: "923",
        star:"4"
    },
    {
        id: "3",
        title: "St. Giles Southkey",
        location: "Tebrau, Johor Bahru",
        rating: "4.4",
        image: "https://assets.nst.com.my/images/articles/photo1667730755_1667732611.jpg",
        alt: "giles",  
        tags: ["Breakfast included", "Non-smoking"],
        price: "$ 98",
        review: "1,323",
        star:"4"
    },
    {
        id: "4",
        title: "Johor Austin Height IKEA",
        location: "Gelang Patah/Legoland, Johor Bahru",
        rating: "5",
        image: "https://pix8.agoda.net/hotelImages/44882768/0/8ba6288cc64b0f5806cf44e629d66a0f.jpg?ce=0&s=1024x768",
        alt: "ikea",  
        tags: ["Car park", "Great for families"],
        price: "$ 33",
        review: "2",
        star:"5"
    },
    {
        id: "5",
        title: "Forest City Golf Hotel",
        location: "Gelang Patah/Legoland, Johor Bahru",
        rating: "4.1",
        image: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/303201344.jpg?k=4cd6aa179017d7700c0f14584c385b2137c9e55e031fe156b6cf8cc55b68e2a8&o=&hp=1",
        alt: "forest",  
        tags: ["Car park", "Swimming pool", "Internet", "Breakfast included"],
        price: "$ 79",
        review: "3,122",
        star:"4"
      },
      {
        id: "6",
        title: "Almas Puteri Harbour",
        location: "Gelang Patah/Legoland, Johor Bahru",
        rating: "4.8",
        image: "https://pix8.agoda.net/hotelImages/105/10591419/10591419_19122017440086127987.jpg?ca=9&ce=1&s=1024x768",
        alt: "stay",  
        tags: ["Non-smoking", "Great for families"],
        price: "$ 39",
        review: "730",
        star:"5"
      },
      {
        id: "7",
        title: "Country Garden Danga Bay",
        location: "Gelang Patah/Legoland, Johor Bahru",
        rating: "4.8",
        image: "https://pix8.agoda.net/hotelImages/39821595/0/3643b68a94e36bc8e39c988bebc40e86.jpg?ce=0&s=1024x768",
        alt: "stayrene",  
        tags: ["Non-smoking"],
        price: "$ 69",
        review: "19",
        star:"5"
      },
      {
        id: "8",
        title: "Royale Cottage",
        location: "Tebrau, Johor Bahru",
        rating: "4.8",
        image: "https://pix8.agoda.net/hotelImages/30444778/0/d6c0db26d6eb8640e7f62dea67598d8e.jpg?ca=26&ce=0&s=1024x768",
        alt: "royalecottage",  
        tags: ["Swimming pool", "Great for families"],
        price: "$ 72",
        review: "169",
        star:"5"
      },
      {
        id: "9",
        title: "SKS Pavillion Residences",
        location: "Johor Bahru City Center, Johor Bahru",
        rating: "4.3",
        image: "https://pix8.agoda.net/hotelImages/228/22889890/22889890_21031009580095355943.jpg?ca=17&ce=1&s=1024x768",
        alt: "stayrene2",  
        tags: ["Non-smoking"],
        price: "$ 74",
        review: "40",
        star:"5"
      },
      {
        id: "10",
        title: "Thistle Johor Bahru Hotel",
        location: "Johor Bahru City Center, Johor Bahru",
        rating: "3.2",
        image: "https://pix8.agoda.net/hotelImages/159191/-1/02eafb63c3480c4e0394c650f220bc97.jpg?ca=7&ce=1&s=1024x768",
        alt: "thistle",  
        tags: ["Non-smoking", "Breakfast included"],
        price: "$ 88",
        review: "1,920",
        star:"5"
      },
      {
        id: "11",
        title: "Cactus Hotel Skudai",
        location: "Skudai, Johor Bahru",
        rating: "3.2",
        image: "https://pix8.agoda.net/hotelImages/38145485/0/81e372e0d531be341801363ff3a1e0c0.jpg?ce=0&s=1024x768",
        tags: ["Non-smoking"],
        price: "$ 48",
        review: "220",
        star:"5"
      },
      {
        id: "11",
        title: "Forest City Marina Hotel",
        location: "Gelang Patah/Legoland, Johor Bahru",
        rating: "4.0",
        image: "https://pix8.agoda.net/hotelImages/2053200/-1/bf735e55669fac6b103a8342070b9f71.jpg?ca=20&ce=0&s=1024x768",
        alt: "marina",  
        tags: ["Non-smoking", "Swimming pool"],
        price: "$ 48",
        review: "1,100",
        star:"5"
      },
      {
        id: "12",
        title: "Fives Hotel Johor Bahru",
        location: "Johor Bahru City Center, Johor Bahru",
        rating: "4.3",
        image: "https://pix8.agoda.net/hotelImages/28013032/-1/ed3dfe29bf6efee81cd1065bf03a6a42.jpg?ca=22&ce=0&s=1024x768",
        alt: "fives",  
        tags: ["Non-smoking", "Great for families", "Swimming pool"],
        price: "$ 124",
        review: "7,241",
        star:"5"
      },
      {
        id: "13",
        title: "The Legoland Malaysia Resort",
        location: "Gelang Patah/Legoland, Johor Bahru",
        rating: "4.2",
        image: "https://pix8.agoda.net/hotelImages/625/625873/625873_14051916170019476676.jpg?ca=2&ce=1&s=1024x768",
        alt: "legoland",  
        tags: ["Non-smoking", "Great for families", "Swimming pool"],
        price: "$ 314",
        review: "5,241",
        star:"5"
      },
    
  ];