// SEBELUM TUKAR ANY DATA, CHECK DULU CODE SETIAP PRODUCT!

// HOTELSALL
export const dataHotelsAll = [
  {
    id: 342,
    code: "HOTELSALL-SEL02-BBB",
    name: "Hotels in Bandar Baru Bangi, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc41138",
    image: "/images/hotel_all.png",
    price: 73,
    currency: "MYR",
    description: "Find the perfect place to stay in Bandar Baru Bangi with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.5,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 343,
    code: "HOTELSALL-SEL03-BPA",
    name: "Hotels in Bandar Puncak Alam, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc107018",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Bandar Puncak Alam with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 344,
    code: "HOTELSALL-SEL04-BGI",
    name: "Hotels in Bangi, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc21795",
    image: "/images/hotel_all.png",
    price: 60,
    currency: "MYR",
    description: "Find the perfect place to stay in Bangi with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 345,
    code: "HOTELSALL-SEL05-BTG",
    name: "Hotels in Banting, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc86854",
    image: "/images/hotel_all.png",
    price: 58,
    currency: "MYR",
    description: "Find the perfect place to stay in Banting with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 346,
    code: "HOTELSALL-SEL06-BKL",
    name: "Hotels in Batang Kali, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc306469",
    image: "/images/hotel_all.png",
    price: 54,
    currency: "MYR",
    description: "Find the perfect place to stay in Batang Kali with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.7,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 347,
    code: "HOTELSALL-SEL07-BAG",
    name: "Hotels in Batu Arang, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc303195",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Batu Arang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 348,
    code: "HOTELSALL-SEL08-BCV",
    name: "Hotels in Batu Caves, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc70474",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Batu Caves with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 349,
    code: "HOTELSALL-SEL09-BJY",
    name: "Hotels in Bestari Jaya, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319042",
    image: "/images/hotel_all.png",
    price: 54,
    currency: "MYR",
    description: "Find the perfect place to stay in Bestari Jaya with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.4,
    total_review: "",
    tags: ["selangor", "all"],
  },
  // {
  //   id: 350,
  //   code: "HOTELSALL-SEL10-CRS",
  //   name: "Hotels in Cheras, Selangor",
  //   link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&enc_cid=9&url=%2Fhotels-dateless%2Fc145997",
  //   image: "/images/hotel_all.png",
  //   price: 87,
  //   currency: "MYR",
  //   description: "Find the perfect place to stay in Bestari Jaya with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
  //   hotel_class: 1,
  //   rating: 6.9,
  //   total_review: "",
  //   tags: ["selangor", "all"],
  // },
  {
    id: 351,
    code: "HOTELSALL-SEL11-CBJ",
    name: "Hotels in Cyberjaya, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc19895",
    image: "/images/hotel_all.png",
    price: 60,
    currency: "MYR",
    description: "Find the perfect place to stay in Cyberjaya with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 352,
    code: "HOTELSALL-SEL12-DKL",
    name: "Hotels in Dengkil, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc23140",
    image: "/images/hotel_all.png",
    price: 48,
    currency: "MYR",
    description: "Find the perfect place to stay in Dengkil with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 353,
    code: "HOTELSALL-SEL13-HLG",
    name: "Hotels in Hulu Langat, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc312695",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Hulu Langat with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.1,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 354,
    code: "HOTELSALL-SEL14-JJM",
    name: "Hotels in Jenjarom, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319119",
    image: "/images/hotel_all.png",
    price: 58,
    currency: "MYR",
    description: "Find the perfect place to stay in Jenjarom with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.3,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 355,
    code: "HOTELSALL-SEL15-JRM",
    name: "Hotels in Jeram, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319424",
    image: "/images/hotel_all.png",
    price: 88,
    currency: "MYR",
    description: "Find the perfect place to stay in Jeram with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 9.5,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 356,
    code: "HOTELSALL-SEL16-KJG",
    name: "Hotels in Kajang, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc34205",
    image: "/images/hotel_all.png",
    price: 32,
    currency: "MYR",
    description: "Find the perfect place to stay in Kajang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.4,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 357,
    code: "HOTELSALL-SEL17-KPR",
    name: "Hotels in Kapar, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc72257",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Kapar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 358,
    code: "HOTELSALL-SEL18-KRG",
    name: "Hotels in Kerling, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc318741",
    image: "/images/hotel_all.png",
    price: 87,
    currency: "MYR",
    description: "Find the perfect place to stay in Kerling with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 7.4,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 359,
    code: "HOTELSALL-SEL19-KLG",
    name: "Hotels in Klang, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc17519",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Klang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 360,
    code: "HOTELSALL-SEL20-KKB",
    name: "Hotels in Kuala Kubu Bharu, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc104527",
    image: "/images/hotel_all.png",
    price: 154,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Kubu Bharu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 1,
    rating: 9.0,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 361,
    code: "HOTELSALL-SEL21-KSR",
    name: "Hotels in Kuala Selangor, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc69413",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Selangor with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.4,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 362,
    code: "HOTELSALL-SEL22-PGJ",
    name: "Hotels in Petaling Jaya, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc23450",
    image: "/images/hotel_all.png",
    price: 30,
    currency: "MYR",
    description: "Find the perfect place to stay in Petaling Jaya with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.4,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 363,
    code: "HOTELSALL-SEL23-PKG",
    name: "Hotels in Port Klang, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc54101",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Port Klang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 364,
    code: "HOTELSALL-SEL24-PCG",
    name: "Hotels in Puchong, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc23060",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Puchong with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 365,
    code: "HOTELSALL-SEL25-PKM",
    name: "Hotels in Pulau Ketam, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc112366",
    image: "/images/hotel_all.png",
    price: 44,
    currency: "MYR",
    description: "Find the perfect place to stay in Pulau Ketam with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 366,
    code: "HOTELSALL-SEL26-PTJ",
    name: "Hotels in Putrajaya, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc47577",
    image: "/images/hotel_all.png",
    price: 48,
    currency: "MYR",
    description: "Find the perfect place to stay in Putrajaya with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 367,
    code: "HOTELSALL-SEL27-RWG",
    name: "Hotels in Rawang, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc303131",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Rawang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 368,
    code: "HOTELSALL-SEL28-SBB",
    name: "Hotels in Sabak Bernam, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc56212",
    image: "/images/hotel_all.png",
    price: 93,
    currency: "MYR",
    description: "Find the perfect place to stay in Sabak Bernam with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 7.4,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 369,
    code: "HOTELSALL-SEL29-SKN",
    name: "Hotels in Sekinchan, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319478",
    image: "/images/hotel_all.png",
    price: 100,
    currency: "MYR",
    description: "Find the perfect place to stay in Sekinchan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 6.5,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 370,
    code: "HOTELSALL-SEL30-SGJ",
    name: "Hotels in Selayang Jaya, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc14243",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Selayang Jaya with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 371,
    code: "HOTELSALL-SEL31-SMY",
    name: "Hotels in Semenyih, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc86861",
    image: "/images/hotel_all.png",
    price: 31,
    currency: "MYR",
    description: "Find the perfect place to stay in Semenyih with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.5,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 372,
    code: "HOTELSALL-SEL32-SPG",
    name: "Hotels in Sepang, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc48542",
    image: "/images/hotel_all.png",
    price: 59,
    currency: "MYR",
    description: "Find the perfect place to stay in Sepang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 373,
    code: "HOTELSALL-SEL33-SDG",
    name: "Hotels in Serdang, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc120281",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Serdang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 374,
    code: "HOTELSALL-SEL34-SRH",
    name: "Hotels in Serendah, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc100214",
    image: "/images/hotel_all.png",
    price: 54,
    currency: "MYR",
    description: "Find the perfect place to stay in Serendah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 7.7,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 375,
    code: "HOTELSALL-SEL35-SKN",
    name: "Hotels in Seri Kembangan, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc48550",
    image: "/images/hotel_all.png",
    price: 31,
    currency: "MYR",
    description: "Find the perfect place to stay in Seri Kembangan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 376,
    code: "HOTELSALL-SEL36-SAL",
    name: "Hotels in Shah Alam, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc21700",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Shah Alam with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 377,
    code: "HOTELSALL-SEL37-SBR",
    name: "Hotels in Sungai Besar, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc321203",
    image: "/images/hotel_all.png",
    price: 142,
    currency: "MYR",
    description: "Find the perfect place to stay in Sungai Besar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 9.1,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 378,
    code: "HOTELSALL-SEL38-SBH",
    name: "Hotels in Sungai Buloh, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc309777",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Sungai Buloh with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 379,
    code: "HOTELSALL-SEL39-SJY",
    name: "Hotels in Subang Jaya, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc2331",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Subang Jaya with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 380,
    code: "HOTELSALL-SEL40-TMI",
    name: "Hotels in Taman Melawati, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc73743",
    image: "/images/hotel_all.png",
    price: 30,
    currency: "MYR",
    description: "Find the perfect place to stay in Taman Melawati with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 381,
    code: "HOTELSALL-SEL41-TKR",
    name: "Hotels in Tanjung Karang, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc318727",
    image: "/images/hotel_all.png",
    price: 108,
    currency: "MYR",
    description: "Find the perfect place to stay in Tanjung Karang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 8.3,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 382,
    code: "HOTELSALL-SEL42-TSP",
    name: "Hotels in Tanjung Sepat, Selangor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc318888",
    image: "/images/hotel_all.png",
    price: 58,
    currency: "MYR",
    description: "Find the perfect place to stay in Tanjung Sepat with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["selangor", "all"],
  },
  {
    id: 383,
    code: "HOTELSALL-KUL43-KUL",
    name: "Hotels in Kuala Lumpur",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc4723",
    image: "/images/hotel_all.png",
    price: 29,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Lumpur with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["kl", "all"],
  },
  {
    id: 384,
    code: "HOTELSALL-KUL44-AMP",
    name: "Hotels in Ampang, Kuala Lumpur",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc32166",
    image: "/images/hotel_all.png",
    price: 30,
    currency: "MYR",
    description: "Find the perfect place to stay in Ampang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["kl", "all"],
  },
  {
    id: 385,
    code: "HOTELSALL-KUL45-CHR",
    name: "Hotels in Cheras, Kuala Lumpur",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc145997",
    image: "/images/hotel_all.png",
    price: 87,
    currency: "MYR",
    description: "Find the perfect place to stay in Ampang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.1,
    total_review: "",
    tags: ["kl", "all"],
  },
  {
    id: 386,
    code: "HOTELSALL-KUL46-KDS",
    name: "Hotels in Kota Damansara, Kuala Lumpur",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc84310",
    image: "/images/hotel_all.png",
    price: 30,
    currency: "MYR",
    description: "Find the perfect place to stay in Kota Damansara with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["kl", "all"],
  },
  {
    id: 387,
    code: "HOTELSALL-KUL47-PUT",
    name: "Hotels in Putrajaya, Kuala Lumpur",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc47577",
    image: "/images/hotel_all.png",
    price: 48,
    currency: "MYR",
    description: "Find the perfect place to stay in Putrajaya with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["kl", "all"],
  },
  {
    id: 388,
    code: "HOTELSALL-KUL48-STP",
    name: "Hotels in Setapak, Kuala Lumpur",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc120807",
    image: "/images/hotel_all.png",
    price: 45,
    currency: "MYR",
    description: "Find the perfect place to stay in Setapak with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["kl", "all"],
  },
  {
    id: 389,
    code: "HOTELSALL-KEL49-BCK",
    name: "Hotels in Bachok, Kelantan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc69410",
    image: "/images/hotel_all.png",
    price: 46,
    currency: "MYR",
    description: "Find the perfect place to stay in Bachok with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 9.1,
    total_review: "",
    tags: ["kelantan", "all"],
  },
  {
    id: 390,
    code: "HOTELSALL-KEL50-CRR",
    name: "Hotels in Cherang Ruku, Kelantan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc312224",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Cherang Ruku with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.7,
    total_review: "",
    tags: ["kelantan", "all"],
  },
  {
    id: 391,
    code: "HOTELSALL-KEL51-GMS",
    name: "Hotels in Gua Musang, Kelantan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc83459",
    image: "/images/hotel_all.png",
    price: 45,
    currency: "MYR",
    description: "Find the perfect place to stay in Gua Musang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.2,
    total_review: "",
    tags: ["kelantan", "all"],
  },
  {
    id: 392,
    code: "HOTELSALL-KEL52-KRH",
    name: "Hotels in Ketereh, Kelantan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc83459",
    image: "/images/hotel_all.png",
    price: 65,
    currency: "MYR",
    description: "Find the perfect place to stay in Ketereh with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.4,
    total_review: "",
    tags: ["kelantan", "all"],
  },
  {
    id: 393,
    code: "HOTELSALL-KEL53-KBR",
    name: "Hotels in Kota Bharu, Kelantan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc7809",
    image: "/images/hotel_all.png",
    price: 46,
    currency: "MYR",
    description: "Find the perfect place to stay in Kota Bharu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 9.1,
    total_review: "",
    tags: ["kelantan", "all"],
  },
  {
    id: 394,
    code: "HOTELSALL-KEL54-KLK",
    name: "Hotels in Kuala Krai, Kelantan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc315065",
    image: "/images/hotel_all.png",
    price: 69,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Krai with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 8.8,
    total_review: "",
    tags: ["kelantan", "all"],
  },
  {
    id: 395,
    code: "HOTELSALL-KEL55-MCG",
    name: "Hotels in Machang, Kelantan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc97563",
    image: "/images/hotel_all.png",
    price: 87,
    currency: "MYR",
    description: "Find the perfect place to stay in Machang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["kelantan", "all"],
  },
  {
    id: 396,
    code: "HOTELSALL-KEL56-PSM",
    name: "Hotels in Pasir Mas, Kelantan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc69411",
    image: "/images/hotel_all.png",
    price: 46,
    currency: "MYR",
    description: "Find the perfect place to stay in Pasir Mas with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 9.1,
    total_review: "",
    tags: ["kelantan", "all"],
  },
  {
    id: 397,
    code: "HOTELSALL-KEL57-PSP",
    name: "Hotels in Pasir Puteh, Kelantan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc302889",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Pasir Puteh with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.7,
    total_review: "",
    tags: ["kelantan", "all"],
  },
  {
    id: 398,
    code: "HOTELSALL-KEL58-RPJ",
    name: "Hotels in Rantau Panjang, Kelantan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc87283",
    image: "/images/hotel_all.png",
    price: 73,
    currency: "MYR",
    description: "Find the perfect place to stay in Rantau Panjang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.2,
    total_review: "",
    tags: ["kelantan", "all"],
  },
  {
    id: 399,
    code: "HOTELSALL-KEL59-THM",
    name: "Hotels in Tanah Merah, Kelantan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc103644",
    image: "/images/hotel_all.png",
    price: 87,
    currency: "MYR",
    description: "Find the perfect place to stay in Tanah Merah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["kelantan", "all"],
  },
  {
    id: 400,
    code: "HOTELSALL-KEL60-THM",
    name: "Hotels in Tumpat, Kelantan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc72221",
    image: "/images/hotel_all.png",
    price: 46,
    currency: "MYR",
    description: "Find the perfect place to stay in Tumpat with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 9.1,
    total_review: "",
    tags: ["kelantan", "all"],
  },
  // {
  //   id: 401,
  //   code: "HOTELSALL-KEL61-THM",
  //   name: "Hotels in Tumpat, Kelantan",
  //   link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc72221",
  //   image: "/images/hotel_all.png",
  //   price: 46,
  //   currency: "MYR",
  //   description: "Find the perfect place to stay in Tumpat with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
  //   hotel_class: 4,
  //   rating: 9.1,
  //   total_review: "",
  //   tags: ["kelantan", "all"],
  // },
  {
    id: 402,
    code: "HOTELSALL-TRG62-BPI",
    name: "Hotels in Bandar Permaisuri, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110568",
    image: "/images/hotel_all.png",
    price: 65,
    currency: "MYR",
    description: "Find the perfect place to stay in Bandar Permaisuri with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.0,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 403,
    code: "HOTELSALL-TRG63-CKI",
    name: "Hotels in Chukai, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc24841",
    image: "/images/hotel_all.png",
    price: 46,
    currency: "MYR",
    description: "Find the perfect place to stay in Chukai with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.0,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 404,
    code: "HOTELSALL-TRG64-DGN",
    name: "Hotels in Dungun, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc12216",
    image: "/images/hotel_all.png",
    price: 45,
    currency: "MYR",
    description: "Find the perfect place to stay in Dungun with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 405,
    code: "HOTELSALL-TRG65-JTH",
    name: "Hotels in Jerteh, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc34122",
    image: "/images/hotel_all.png",
    price: 53,
    currency: "MYR",
    description: "Find the perfect place to stay in Jerteh with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 9.7,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 406,
    code: "HOTELSALL-TRG66-JTH",
    name: "Hotels in Kerteh, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc44687",
    image: "/images/hotel_all.png",
    price: 85,
    currency: "MYR",
    description: "Find the perfect place to stay in Kerteh with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.1,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  // {
  //   id: 407,
  //   code: "HOTELSALL-TRG67-KTH",
  //   name: "Hotels in Kerteh, Terengganu",
  //   link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc315131",
  //   image: "/images/hotel_all.png",
  //   price: 85,
  //   currency: "MYR",
  //   description: "Find the perfect place to stay in Kerteh with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
  //   hotel_class: 3,
  //   rating: 9.1,
  //   total_review: "",
  //   tags: ["terengganu", "all"],
  // },
  {
    id: 408,
    code: "HOTELSALL-TRG68-KJL",
    name: "Hotels in Kijal, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc44687",
    image: "/images/hotel_all.png",
    price: 85,
    currency: "MYR",
    description: "Find the perfect place to stay in Kijal with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 9.1,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 409,
    code: "HOTELSALL-TRG69-KBG",
    name: "Hotels in Kuala Berang, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc306837",
    image: "/images/hotel_all.png",
    price: 87,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Berang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 9.4,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 410,
    code: "HOTELSALL-TRG70-KBS",
    name: "Hotels in Kuala Besut, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc69408",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Besut with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.7,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 411,
    code: "HOTELSALL-TRG71-KTG",
    name: "Hotels in Kuala Terengganu, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc26624",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Terengganu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.3,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 412,
    code: "HOTELSALL-TRG72-MRG",
    name: "Hotels in Marang, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc69406",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Marang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.1,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 413,
    code: "HOTELSALL-TRG73-PKA",
    name: "Hotels in Paka, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc69412",
    image: "/images/hotel_all.png",
    price: 85,
    currency: "MYR",
    description: "Find the perfect place to stay in Paka with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.1,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 414,
    code: "HOTELSALL-TRG74-PPB",
    name: "Hotels in Pulau Lang Tengah, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc80301",
    image: "/images/hotel_all.png",
    price: 85,
    currency: "MYR",
    description: "Find the perfect place to stay in Pulau Lang Tengah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.8,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 415,
    code: "HOTELSALL-TRG75-PPB",
    name: "Hotels in Pulau Perhentian Besar, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc312818",
    image: "/images/hotel_all.png",
    price: 85,
    currency: "MYR",
    description: "Find the perfect place to stay in Pulau Perhentian Besar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.5,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 416,
    code: "HOTELSALL-TRG76-STU",
    name: "Hotels in Redang Island, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc54218",
    image: "/images/hotel_all.png",
    price: 208,
    currency: "MYR",
    description: "Find the perfect place to stay in Redang Island with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.8,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 417,
    code: "HOTELSALL-TRG77-STU",
    name: "Hotels in Setiu, Terengganu",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc123101",
    image: "/images/hotel_all.png",
    price: 65,
    currency: "MYR",
    description: "Find the perfect place to stay in Setiu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.0,
    total_review: "",
    tags: ["terengganu", "all"],
  },
  {
    id: 418,
    code: "HOTELSALL-PHG78-BLK",
    name: "Hotels in Balok, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc318775",
    image: "/images/hotel_all.png",
    price: 83,
    currency: "MYR",
    description: "Find the perfect place to stay in Balok with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.7,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 419,
    code: "HOTELSALL-PHG79-BBR",
    name: "Hotels in Bandar Bera, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc323600",
    image: "/images/hotel_all.png",
    price: 60,
    currency: "MYR",
    description: "Find the perfect place to stay in Bandar Bera with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.4,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 420,
    code: "HOTELSALL-PHG80-BBR",
    name: "Hotels in Bandar Tun Abdul Razak, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc323600",
    image: "/images/hotel_all.png",
    price: 107,
    currency: "MYR",
    description: "Find the perfect place to stay in Bandar Tun Abdul Razak with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 7.5,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 421,
    code: "HOTELSALL-PHG81-BTR",
    name: "Hotels in Bandar Tun Razak, Jengka, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc113013",
    image: "/images/hotel_all.png",
    price: 85,
    currency: "MYR",
    description: "Find the perfect place to stay in Bandar Tun Razak, Jengka with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 9.5,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 422,
    code: "HOTELSALL-PHG82-BTA",
    name: "Hotels in Benta, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc324522",
    image: "/images/hotel_all.png",
    price: 55,
    currency: "MYR",
    description: "Find the perfect place to stay in Benta with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.8,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 423,
    code: "HOTELSALL-PHG83-BTG",
    name: "Hotels in Bentong, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc9169",
    image: "/images/hotel_all.png",
    price: 59,
    currency: "MYR",
    description: "Find the perfect place to stay in Bentong with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 424,
    code: "HOTELSALL-PHG84-BCG",
    name: "Hotels in Brinchang, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc108821",
    image: "/images/hotel_all.png",
    price: 43,
    currency: "MYR",
    description: "Find the perfect place to stay in Brinchang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 425,
    code: "HOTELSALL-PHG85-BKT",
    name: "Hotels in Bukit Tinggi, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc41801",
    image: "/images/hotel_all.png",
    price: 119,
    currency: "MYR",
    description: "Find the perfect place to stay in Bukit Tinggi with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.7,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 426,
    code: "HOTELSALL-PHG86-FRH",
    name: "Hotels in Frasers Hill, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc43442",
    image: "/images/hotel_all.png",
    price: 147,
    currency: "MYR",
    description: "Find the perfect place to stay in Frasers Hill with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.8,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 427,
    code: "HOTELSALL-PHG87-GBG",
    name: "Hotels in Gambang, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc72825",
    image: "/images/hotel_all.png",
    price: 108,
    currency: "MYR",
    description: "Find the perfect place to stay in Gambang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 1,
    rating: 9.0,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 428,
    code: "HOTELSALL-PHG88-GTH",
    name: "Hotels in Genting Highlands, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc52622",
    image: "/images/hotel_all.png",
    price: 90,
    currency: "MYR",
    description: "Find the perfect place to stay in Genting Highlands with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.7,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 429,
    code: "HOTELSALL-PHG89-JRT",
    name: "Hotels in Jerantut, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc10086",
    image: "/images/hotel_all.png",
    price: 44,
    currency: "MYR",
    description: "Find the perfect place to stay in Jerantut with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 7.9,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 430,
    code: "HOTELSALL-PHG90-KRK",
    name: "Hotels in Karak, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc116430",
    image: "/images/hotel_all.png",
    price: 59,
    currency: "MYR",
    description: "Find the perfect place to stay in Karak with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 431,
    code: "HOTELSALL-PHG91-KLL",
    name: "Hotels in Kuala Lipis, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc72375",
    image: "/images/hotel_all.png",
    price: 55,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Lipis with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.8,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 432,
    code: "HOTELSALL-PHG92-KLR",
    name: "Hotels in Kuala Rompin, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc72392",
    image: "/images/hotel_all.png",
    price: 79,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Rompin with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.1,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 433,
    code: "HOTELSALL-PHG93-KTN",
    name: "Hotels in Kuantan, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc31516",
    image: "/images/hotel_all.png",
    price: 40,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuantan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 434,
    code: "HOTELSALL-PHG94-MKB",
    name: "Hotels in Mentekab, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc80338",
    image: "/images/hotel_all.png",
    price: 60,
    currency: "MYR",
    description: "Find the perfect place to stay in Mentekab with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.4,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 435,
    code: "HOTELSALL-PHG95-PDT",
    name: "Hotels in Padang Tengku, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc151883",
    image: "/images/hotel_all.png",
    price: 55,
    currency: "MYR",
    description: "Find the perfect place to stay in Padang Tengku with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.8,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 436,
    code: "HOTELSALL-PHG96-PKN",
    name: "Hotels in Pekan, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319237",
    image: "/images/hotel_all.png",
    price: 64,
    currency: "MYR",
    description: "Find the perfect place to stay in Pekan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.0,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 437,
    code: "HOTELSALL-PHG97-RUB",
    name: "Hotels in Raub, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc18939",
    image: "/images/hotel_all.png",
    price: 70,
    currency: "MYR",
    description: "Find the perfect place to stay in Raub with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.2,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 438,
    code: "HOTELSALL-PHG98-RLT",
    name: "Hotels in Ringlet, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc318927",
    image: "/images/hotel_all.png",
    price: 220,
    currency: "MYR",
    description: "Find the perfect place to stay in Ringlet with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 9.1,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 439,
    code: "HOTELSALL-PHG99-SLG",
    name: "Hotels in Sungai Lembing, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc304643",
    image: "/images/hotel_all.png",
    price: 72,
    currency: "MYR",
    description: "Find the perfect place to stay in Sungai Lembing with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.1,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 440,
    code: "HOTELSALL-PHG100-THR",
    name: "Hotels in Tanah Rata, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc62080",
    image: "/images/hotel_all.png",
    price: 43,
    currency: "MYR",
    description: "Find the perfect place to stay in Tanah Rata with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 441,
    code: "HOTELSALL-PHG101-TLH",
    name: "Hotels in Temerloh, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc304351",
    image: "/images/hotel_all.png",
    price: 43,
    currency: "MYR",
    description: "Find the perfect place to stay in Temerloh with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.2,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 442,
    code: "HOTELSALL-PHG102-TRG",
    name: "Hotels in Teriang, Pahang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc322412",
    image: "/images/hotel_all.png",
    price: 60,
    currency: "MYR",
    description: "Find the perfect place to stay in Teriang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.4,
    total_review: "",
    tags: ["pahang", "all"],
  },
  {
    id: 443,
    code: "HOTELSALL-MEL103-MLC",
    name: "Hotels in Malacca, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc19573",
    image: "/images/hotel_all.png",
    price: 21,
    currency: "MYR",
    description: "Find the perfect place to stay in Malacca with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 444,
    code: "HOTELSALL-MEL104-ARG",
    name: "Hotels in Alor Gajah, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc74224",
    image: "/images/hotel_all.png",
    price: 21,
    currency: "MYR",
    description: "Find the perfect place to stay in Alor Gajah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 445,
    code: "HOTELSALL-MEL105-AYK",
    name: "Hotels in Ayer Keroh, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319107",
    image: "/images/hotel_all.png",
    price: 22,
    currency: "MYR",
    description: "Find the perfect place to stay in Ayer Keroh with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 446,
    code: "HOTELSALL-MEL106-BBR",
    name: "Hotels in Batu Berendam, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110456",
    image: "/images/hotel_all.png",
    price: 22,
    currency: "MYR",
    description: "Find the perfect place to stay in Batu Berendam with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 447,
    code: "HOTELSALL-MEL107-BBN",
    name: "Hotels in Bemban, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110445",
    image: "/images/hotel_all.png",
    price: 22,
    currency: "MYR",
    description: "Find the perfect place to stay in Bemban with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 448,
    code: "HOTELSALL-MEL108-DTG",
    name: "Hotels in Durian Tunggal, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110461",
    image: "/images/hotel_all.png",
    price: 22,
    currency: "MYR",
    description: "Find the perfect place to stay in Durian Tunggal with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 449,
    code: "HOTELSALL-MEL109-JSN",
    name: "Hotels in Jasin, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc11784",
    image: "/images/hotel_all.png",
    price: 22,
    currency: "MYR",
    description: "Find the perfect place to stay in Jasin with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 450,
    code: "HOTELSALL-MEL110-KSB",
    name: "Hotels in Kuala Sungai Baru, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319341",
    image: "/images/hotel_all.png",
    price: 58,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Sungai Baru with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.0,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 451,
    code: "HOTELSALL-MEL111-LND",
    name: "Hotels in Lendu, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc312238",
    image: "/images/hotel_all.png",
    price: 22,
    currency: "MYR",
    description: "Find the perfect place to stay in Lendu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 452,
    code: "HOTELSALL-MEL112-LBC",
    name: "Hotels in Lubuk China, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc318988",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Lubuk China with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.3,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 453,
    code: "HOTELSALL-MEL113-MJT",
    name: "Hotels in Masjid Tanah, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319344",
    image: "/images/hotel_all.png",
    price: 22,
    currency: "MYR",
    description: "Find the perfect place to stay in Masjid Tanah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 454,
    code: "HOTELSALL-MEL114-SPA",
    name: "Hotels in Simpang Ampat, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc104309",
    image: "/images/hotel_all.png",
    price: 22,
    currency: "MYR",
    description: "Find the perfect place to stay in Simpang Ampat with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 455,
    code: "HOTELSALL-MEL115-SGU",
    name: "Hotels in Sungai Udang, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc74543",
    image: "/images/hotel_all.png",
    price: 22,
    currency: "MYR",
    description: "Find the perfect place to stay in Sungai Udang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 456,
    code: "HOTELSALL-MEL116-TPN",
    name: "Hotels in Tampin, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc312237",
    image: "/images/hotel_all.png",
    price: 22,
    currency: "MYR",
    description: "Find the perfect place to stay in Tampin with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 457,
    code: "HOTELSALL-MEL117-TJK",
    name: "Hotels in Tanjung Kling, Melaka",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc318967",
    image: "/images/hotel_all.png",
    price: 22,
    currency: "MYR",
    description: "Find the perfect place to stay in Tanjung Kling with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["melaka", "all"],
  },
  {
    id: 458,
    code: "HOTELSALL-PNG118-PNG",
    name: "Hotels in Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc19799",
    image: "/images/hotel_all.png",
    price: 31,
    currency: "MYR",
    description: "Find the perfect place to stay in Penang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 459,
    code: "HOTELSALL-PNG119-AYI",
    name: "Hotels in Air Itam (Ayer Itam), Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110546",
    image: "/images/hotel_all.png",
    price: 63,
    currency: "MYR",
    description: "Find the perfect place to stay in Air Itam (Ayer Itam) with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.9,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 460,
    code: "HOTELSALL-PNG120-BLP",
    name: "Hotels in Balik Pulau, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc69405",
    image: "/images/hotel_all.png",
    price: 63,
    currency: "MYR",
    description: "Find the perfect place to stay in Balik Pulau with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.9,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 461,
    code: "HOTELSALL-PNG121-BFI",
    name: "Hotels in Batu Ferringhi, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc60072",
    image: "/images/hotel_all.png",
    price: 60,
    currency: "MYR",
    description: "Find the perfect place to stay in Batu Ferringhi with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.9,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 462,
    code: "HOTELSALL-PNG122-BYL",
    name: "Hotels in Bayan Lepas, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc324992",
    image: "/images/hotel_all.png",
    price: 63,
    currency: "MYR",
    description: "Find the perfect place to stay in Bayan Lepas with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.9,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 463,
    code: "HOTELSALL-PNG123-BMT",
    name: "Hotels in Bukit Mertajam, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc312699",
    image: "/images/hotel_all.png",
    price: 60,
    currency: "MYR",
    description: "Find the perfect place to stay in Bukit Mertajam with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 464,
    code: "HOTELSALL-PNG124-BTW",
    name: "Hotels in Butterworth, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc3197",
    image: "/images/hotel_all.png",
    price: 31,
    currency: "MYR",
    description: "Find the perfect place to stay in Butterworth with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 465,
    code: "HOTELSALL-PNG125-GLG",
    name: "Hotels in Gelugor, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc107019",
    image: "/images/hotel_all.png",
    price: 31,
    currency: "MYR",
    description: "Find the perfect place to stay in Gelugor with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 466,
    code: "HOTELSALL-PNG126-KPB",
    name: "Hotels in Kepala Batas, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc316683",
    image: "/images/hotel_all.png",
    price: 68,
    currency: "MYR",
    description: "Find the perfect place to stay in Kepala Batas with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.9,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 467,
    code: "HOTELSALL-PNG127-NBT",
    name: "Hotels in Nibong Tebal, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc148299",
    image: "/images/hotel_all.png",
    price: 89,
    currency: "MYR",
    description: "Find the perfect place to stay in Nibong Tebal with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.9,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 468,
    code: "HOTELSALL-PNG128-PRI",
    name: "Hotels in Perai, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc12419",
    image: "/images/hotel_all.png",
    price: 31,
    currency: "MYR",
    description: "Find the perfect place to stay in Perai with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 469,
    code: "HOTELSALL-PNG129-PMP",
    name: "Hotels in Permatang Pauh, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110624",
    image: "/images/hotel_all.png",
    price: 70,
    currency: "MYR",
    description: "Find the perfect place to stay in Permatang Pauh with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.9,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 470,
    code: "HOTELSALL-PNG130-SJW",
    name: "Hotels in Sungai Jawi, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc123125",
    image: "/images/hotel_all.png",
    price: 64,
    currency: "MYR",
    description: "Find the perfect place to stay in Sungai Jawi with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.4,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 471,
    code: "HOTELSALL-PNG131-TJB",
    name: "Hotels in Tanjung Bungah, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc302939",
    image: "/images/hotel_all.png",
    price: 31,
    currency: "MYR",
    description: "Find the perfect place to stay in Tanjung Bungah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 472,
    code: "HOTELSALL-PNG132-TSG",
    name: "Hotels in Tasek Gelugor, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc155090",
    image: "/images/hotel_all.png",
    price: 68,
    currency: "MYR",
    description: "Find the perfect place to stay in Tasek Gelugor with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.9,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 473,
    code: "HOTELSALL-PNG133-TLB",
    name: "Hotels in Teluk Bahang, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc86713",
    image: "/images/hotel_all.png",
    price: 60,
    currency: "MYR",
    description: "Find the perfect place to stay in Teluk Bahang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.9,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 474,
    code: "HOTELSALL-PNG134-TLK",
    name: "Hotels in Teluk Kumbar, Penang",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc316769",
    image: "/images/hotel_all.png",
    price: 70,
    currency: "MYR",
    description: "Find the perfect place to stay in Teluk Kumbar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.9,
    total_review: "",
    tags: ["penang", "all"],
  },
  {
    id: 475,
    code: "HOTELSALL-PRK135-BGD",
    name: "Hotels in Bagan Datoh, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc318865",
    image: "/images/hotel_all.png",
    price: 81,
    currency: "MYR",
    description: "Find the perfect place to stay in Bagan Datoh with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 8.3,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 476,
    code: "HOTELSALL-PRK136-BGS",
    name: "Hotels in Bagan Serai, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc316685",
    image: "/images/hotel_all.png",
    price: 80,
    currency: "MYR",
    description: "Find the perfect place to stay in Bagan Serai with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.9,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 477,
    code: "HOTELSALL-PRK137-BUG",
    name: "Hotels in Batu Gajah, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc104543",
    image: "/images/hotel_all.png",
    price: 42,
    currency: "MYR",
    description: "Find the perfect place to stay in Batu Gajah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 478,
    code: "HOTELSALL-PRK138-BDR",
    name: "Hotels in Bidor, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc98754",
    image: "/images/hotel_all.png",
    price: 77,
    currency: "MYR",
    description: "Find the perfect place to stay in Bidor with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 9.3,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 479,
    code: "HOTELSALL-PRK139-CKJ",
    name: "Hotels in Changkat Jering, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319461",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Changkat Jering with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 480,
    code: "HOTELSALL-PRK140-CMR",
    name: "Hotels in Chemor, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc95805",
    image: "/images/hotel_all.png",
    price: 42,
    currency: "MYR",
    description: "Find the perfect place to stay in Chemor with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 481,
    code: "HOTELSALL-PRK141-GRK",
    name: "Hotels in Gerik, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc60702",
    image: "/images/hotel_all.png",
    price: 157,
    currency: "MYR",
    description: "Find the perfect place to stay in Gerik with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 8.9,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 482,
    code: "HOTELSALL-PRK142-GPG",
    name: "Hotels in Gopeng, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc303500",
    image: "/images/hotel_all.png",
    price: 42,
    currency: "MYR",
    description: "Find the perfect place to stay in Gopeng with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 483,
    code: "HOTELSALL-PRK143-HTM",
    name: "Hotels in Hutan Melintang, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc107021",
    image: "/images/hotel_all.png",
    price: 53,
    currency: "MYR",
    description: "Find the perfect place to stay in Hutan Melintang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.5,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 484,
    code: "HOTELSALL-PRK144-IPH",
    name: "Hotels in Ipoh, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc23340",
    image: "/images/hotel_all.png",
    price: 42,
    currency: "MYR",
    description: "Find the perfect place to stay in Ipoh with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 485,
    code: "HOTELSALL-PRK145-JRM",
    name: "Hotels in Jeram, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319424",
    image: "/images/hotel_all.png",
    price: 88,
    currency: "MYR",
    description: "Find the perfect place to stay in Jeram with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 9.5,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 486,
    code: "HOTELSALL-PRK146-KPR",
    name: "Hotels in Kampar, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc35980",
    image: "/images/hotel_all.png",
    price: 35,
    currency: "MYR",
    description: "Find the perfect place to stay in Kampar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.3,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 487,
    code: "HOTELSALL-PRK147-KGG",
    name: "Hotels in Kampung Gajah, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc329585",
    image: "/images/hotel_all.png",
    price: 53,
    currency: "MYR",
    description: "Find the perfect place to stay in Kampung Gajah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.5,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 488,
    code: "HOTELSALL-PRK148-KMT",
    name: "Hotels in Kamunting, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc318897",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Kamunting with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 489,
    code: "HOTELSALL-PRK149-KIN",
    name: "Hotels in Kerian, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110829",
    image: "/images/hotel_all.png",
    price: 84,
    currency: "MYR",
    description: "Find the perfect place to stay in Kerian with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 9.1,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 490,
    code: "HOTELSALL-PRK150-KKS",
    name: "Hotels in Kuala Kangsar, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc3756",
    image: "/images/hotel_all.png",
    price: 60,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Kangsar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.2,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 491,
    code: "HOTELSALL-PRK151-KSP",
    name: "Hotels in Kuala Sepetang, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc312291",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Sepetang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 492,
    code: "HOTELSALL-PRK152-LGG",
    name: "Hotels in Lenggong, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc80290",
    image: "/images/hotel_all.png",
    price: 154,
    currency: "MYR",
    description: "Find the perfect place to stay in Lenggong with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 8.8,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 493,
    code: "HOTELSALL-PRK153-LMT",
    name: "Hotels in Lumut, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc45569",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Lumut with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 494,
    code: "HOTELSALL-PRK154-LMT",
    name: "Hotels in Pangkor, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc53995",
    image: "/images/hotel_all.png",
    price: 39,
    currency: "MYR",
    description: "Find the perfect place to stay in Pangkor with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 495,
    code: "HOTELSALL-PRK155-PRS",
    name: "Hotels in Pantai Remis, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc316686",
    image: "/images/hotel_all.png",
    price: 69,
    currency: "MYR",
    description: "Find the perfect place to stay in Pantai Remis with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 1,
    rating: 9.2,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 496,
    code: "HOTELSALL-PRK156-SLK",
    name: "Hotels in Selekoh, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc72377",
    image: "/images/hotel_all.png",
    price: 81,
    currency: "MYR",
    description: "Find the perfect place to stay in Selekoh with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 8.3,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 497,
    code: "HOTELSALL-PRK157-SRI",
    name: "Hotels in Seri Iskandar, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc96007",
    image: "/images/hotel_all.png",
    price: 88,
    currency: "MYR",
    description: "Find the perfect place to stay in Seri Iskandar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 7.7,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 498,
    code: "HOTELSALL-PRK158-SIS",
    name: "Hotels in Simpang Empat (Semanggol), Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319094",
    image: "/images/hotel_all.png",
    price: 84,
    currency: "MYR",
    description: "Find the perfect place to stay in Simpang Empat (Semanggol) with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 8.6,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 499,
    code: "HOTELSALL-PRK159-STA",
    name: "Hotels in Sitiawan, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc12108",
    image: "/images/hotel_all.png",
    price: 37,
    currency: "MYR",
    description: "Find the perfect place to stay in Sitiawan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 500,
    code: "HOTELSALL-PRK160-SGI",
    name: "Hotels in Sungkai, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc80283",
    image: "/images/hotel_all.png",
    price: 50,
    currency: "MYR",
    description: "Find the perfect place to stay in Sungkai with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.3,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 501,
    code: "HOTELSALL-PRK161-TPG",
    name: "Hotels in Taiping, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc16654",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Taiping with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 502,
    code: "HOTELSALL-PRK162-TBN",
    name: "Hotels in Tambun, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc305191",
    image: "/images/hotel_all.png",
    price: 42,
    currency: "MYR",
    description: "Find the perfect place to stay in Tambun with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 503,
    code: "HOTELSALL-PRK163-TJM",
    name: "Hotels in Tanjong Malim, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc74541",
    image: "/images/hotel_all.png",
    price: 75,
    currency: "MYR",
    description: "Find the perfect place to stay in Tanjong Malim with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 7.4,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 504,
    code: "HOTELSALL-PRK164-TJR",
    name: "Hotels in Tanjung Rambutan, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc71820",
    image: "/images/hotel_all.png",
    price: 42,
    currency: "MYR",
    description: "Find the perfect place to stay in Tanjung Rambutan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 505,
    code: "HOTELSALL-PRK165-TPH",
    name: "Hotels in Tapah, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc317355",
    image: "/images/hotel_all.png",
    price: 77,
    currency: "MYR",
    description: "Find the perfect place to stay in Tapah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 9.3,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 506,
    code: "HOTELSALL-PRK166-TKT",
    name: "Hotels in Teluk Intan, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc4065",
    image: "/images/hotel_all.png",
    price: 53,
    currency: "MYR",
    description: "Find the perfect place to stay in Teluk Intan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.5,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 507,
    code: "HOTELSALL-PRK167-TRK",
    name: "Hotels in Trolak, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc322410",
    image: "/images/hotel_all.png",
    price: 50,
    currency: "MYR",
    description: "Find the perfect place to stay in Trolak with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.3,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 508,
    code: "HOTELSALL-PRK168-ULK",
    name: "Hotels in Ulu Kinta, Perak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110550",
    image: "/images/hotel_all.png",
    price: 42,
    currency: "MYR",
    description: "Find the perfect place to stay in Ulu Kinta with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["perak", "all"],
  },
  {
    id: 509,
    code: "HOTELSALL-PER169-ARU",
    name: "Hotels in Arau, Perlis",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc15933",
    image: "/images/hotel_all.png",
    price: 62,
    currency: "MYR",
    description: "Find the perfect place to stay in Arau with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.2,
    total_review: "",
    tags: ["perlis", "all"],
  },
  {
    id: 510,
    code: "HOTELSALL-PER170-KBK",
    name: "Hotels in Kaki Bukit, Perlis",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc318787",
    image: "/images/hotel_all.png",
    price: 48,
    currency: "MYR",
    description: "Find the perfect place to stay in Kaki Bukit with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.2,
    total_review: "",
    tags: ["perlis", "all"],
  },
  {
    id: 511,
    code: "HOTELSALL-PER171-KGR",
    name: "Hotels in Kangar, Perlis",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc17044",
    image: "/images/hotel_all.png",
    price: 63,
    currency: "MYR",
    description: "Find the perfect place to stay in Kangar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.2,
    total_review: "",
    tags: ["perlis", "all"],
  },
  {
    id: 512,
    code: "HOTELSALL-PER172-KPR",
    name: "Hotels in Kuala Perlis, Perlis",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc71225",
    image: "/images/hotel_all.png",
    price: 63,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Perlis with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.2,
    total_review: "",
    tags: ["perlis", "all"],
  },
  {
    id: 513,
    code: "HOTELSALL-PER173-PDB",
    name: "Hotels in Padang Besar, Perlis",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc105922",
    image: "/images/hotel_all.png",
    price: 48,
    currency: "MYR",
    description: "Find the perfect place to stay in Padang Besar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 8.6,
    total_review: "",
    tags: ["perlis", "all"],
  },
  {
    id: 514,
    code: "HOTELSALL-JHR174-AYH",
    name: "Hotels in Ayer Hitam, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc93496",
    image: "/images/hotel_all.png",
    price: 77,
    currency: "MYR",
    description: "Find the perfect place to stay in Ayer Hitam with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 9.1,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 515,
    code: "HOTELSALL-JHR175-BPW",
    name: "Hotels in Bandar Penawar, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc62841",
    image: "/images/hotel_all.png",
    price: 83,
    currency: "MYR",
    description: "Find the perfect place to stay in Bandar Penawar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.9,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 516,
    code: "HOTELSALL-JHR176-BDP",
    name: "Hotels in Batu Pahat, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc5101",
    image: "/images/hotel_all.png",
    price: 59,
    currency: "MYR",
    description: "Find the perfect place to stay in Batu Pahat with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.8,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 517,
    code: "HOTELSALL-JHR177-BKK",
    name: "Hotels in Bekok, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc320372",
    image: "/images/hotel_all.png",
    price: 53,
    currency: "MYR",
    description: "Find the perfect place to stay in Bekok with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 7.0,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 518,
    code: "HOTELSALL-JHR178-BNT",
    name: "Hotels in Benut, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc322411",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Benut with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.3,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 519,
    code: "HOTELSALL-JHR179-BNT",
    name: "Hotels in Gelang Patah, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc308828",
    image: "/images/hotel_all.png",
    price: 51,
    currency: "MYR",
    description: "Find the perfect place to stay in Gelang Patah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 520,
    code: "HOTELSALL-JHR180-JMT",
    name: "Hotels in Jementah, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc108678",
    image: "/images/hotel_all.png",
    price: 73,
    currency: "MYR",
    description: "Find the perfect place to stay in Jementah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.3,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 521,
    code: "HOTELSALL-JHR181-JBR",
    name: "Hotels in Johor Bahru, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc44529",
    image: "/images/hotel_all.png",
    price: 36,
    currency: "MYR",
    description: "Find the perfect place to stay in Johor Bahru with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.9,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 522,
    code: "HOTELSALL-JHR182-KLG",
    name: "Hotels in Kluang, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc13518",
    image: "/images/hotel_all.png",
    price: 66,
    currency: "MYR",
    description: "Find the perfect place to stay in Kluang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 523,
    code: "HOTELSALL-JHR183-KTT",
    name: "Hotels in Kota Tinggi, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc19427",
    image: "/images/hotel_all.png",
    price: 75,
    currency: "MYR",
    description: "Find the perfect place to stay in Kota Tinggi with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 7.0,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 524,
    code: "HOTELSALL-JHR184-KKP",
    name: "Hotels in Kukup, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc93498",
    image: "/images/hotel_all.png",
    price: 71,
    currency: "MYR",
    description: "Find the perfect place to stay in Kukup with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 525,
    code: "HOTELSALL-JHR185-KLI",
    name: "Hotels in Kulai, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc69387",
    image: "/images/hotel_all.png",
    price: 49,
    currency: "MYR",
    description: "Find the perfect place to stay in Kulai with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.4,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 526,
    code: "HOTELSALL-JHR186-LBS",
    name: "Hotels in Labis, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc306649",
    image: "/images/hotel_all.png",
    price: 53,
    currency: "MYR",
    description: "Find the perfect place to stay in Labis with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 7.0,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 527,
    code: "HOTELSALL-JHR187-LYG",
    name: "Hotels in Layang Layang, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc147107",
    image: "/images/hotel_all.png",
    price: 49,
    currency: "MYR",
    description: "Find the perfect place to stay in Layang Layang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.2,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 528,
    code: "HOTELSALL-JHR188-MSI",
    name: "Hotels in Masai, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc86865",
    image: "/images/hotel_all.png",
    price: 55,
    currency: "MYR",
    description: "Find the perfect place to stay in Masai with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.7,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 529,
    code: "HOTELSALL-JHR189-MRS",
    name: "Hotels in Mersing, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc46003",
    image: "/images/hotel_all.png",
    price: 78,
    currency: "MYR",
    description: "Find the perfect place to stay in Mersing with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.4,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 530,
    code: "HOTELSALL-JHR190-MUA",
    name: "Hotels in Muar, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc24985",
    image: "/images/hotel_all.png",
    price: 61,
    currency: "MYR",
    description: "Find the perfect place to stay in Muar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.9,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 531,
    code: "HOTELSALL-JHR191-NUS",
    name: "Hotels in Nusajaya, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc107020",
    image: "/images/hotel_all.png",
    price: 107,
    currency: "MYR",
    description: "Find the perfect place to stay in Nusajaya with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.8,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 532,
    code: "HOTELSALL-JHR192-PRR",
    name: "Hotels in Parit Raja, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc95424",
    image: "/images/hotel_all.png",
    price: 77,
    currency: "MYR",
    description: "Find the perfect place to stay in Parit Raja with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 9.1,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 533,
    code: "HOTELSALL-JHR193-PTI",
    name: "Hotels in Pontian, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc16496",
    image: "/images/hotel_all.png",
    price: 71,
    currency: "MYR",
    description: "Find the perfect place to stay in Pontian with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.3,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 534,
    code: "HOTELSALL-JHR194-SGM",
    name: "Hotels in Segamat, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc10785",
    image: "/images/hotel_all.png",
    price: 71,
    currency: "MYR",
    description: "Find the perfect place to stay in Segamat with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.3,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 535,
    code: "HOTELSALL-JHR195-SNI",
    name: "Hotels in Senai, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc86867",
    image: "/images/hotel_all.png",
    price: 49,
    currency: "MYR",
    description: "Find the perfect place to stay in Senai with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.4,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 536,
    code: "HOTELSALL-JHR196-SRG",
    name: "Hotels in Simpang Renggam, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc88595",
    image: "/images/hotel_all.png",
    price: 66,
    currency: "MYR",
    description: "Find the perfect place to stay in Simpang Renggam with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 537,
    code: "HOTELSALL-JHR197-TGK",
    name: "Hotels in Tangkak, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc95223",
    image: "/images/hotel_all.png",
    price: 50,
    currency: "MYR",
    description: "Find the perfect place to stay in Tangkak with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 7.7,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 538,
    code: "HOTELSALL-JHR198-TRM",
    name: "Hotels in Teluk Ramunia, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc69409",
    image: "/images/hotel_all.png",
    price: 30,
    currency: "MYR",
    description: "Find the perfect place to stay in Teluk Ramunia with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.2,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 539,
    code: "HOTELSALL-JHR199-UTR",
    name: "Hotels in Ulu Tiram, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc86866",
    image: "/images/hotel_all.png",
    price: 49,
    currency: "MYR",
    description: "Find the perfect place to stay in Ulu Tiram with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.4,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 540,
    code: "HOTELSALL-JHR200-YGP",
    name: "Hotels in Yong Peng, Johor",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc104023",
    image: "/images/hotel_all.png",
    price: 59,
    currency: "MYR",
    description: "Find the perfect place to stay in Yong Peng with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["johor", "all"],
  },
  {
    id: 541,
    code: "HOTELSALL-KDH201-ALS",
    name: "Hotels in Alor Setar, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc27714",
    image: "/images/hotel_all.png",
    price: 53,
    currency: "MYR",
    description: "Find the perfect place to stay in Alor Setar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.5,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 542,
    code: "HOTELSALL-KDH202-AHT",
    name: "Hotels in Ayer Hitam, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc318669",
    image: "/images/hotel_all.png",
    price: 52,
    currency: "MYR",
    description: "Find the perfect place to stay in Ayer Hitam with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.2,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 543,
    code: "HOTELSALL-KDH203-BLG",
    name: "Hotels in Baling, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110475",
    image: "/images/hotel_all.png",
    price: 96,
    currency: "MYR",
    description: "Find the perfect place to stay in Baling with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.1,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 544,
    code: "HOTELSALL-KDH204-BDG",
    name: "Hotels in Bedong, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc143423",
    image: "/images/hotel_all.png",
    price: 50,
    currency: "MYR",
    description: "Find the perfect place to stay in Bedong with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 545,
    code: "HOTELSALL-KDH205-BKH",
    name: "Hotels in Bukit Kayu Hitam, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc322413",
    image: "/images/hotel_all.png",
    price: 60,
    currency: "MYR",
    description: "Find the perfect place to stay in Bukit Kayu Hitam with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.2,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 546,
    code: "HOTELSALL-KDH206-CLN",
    name: "Hotels in Changloon, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc71224",
    image: "/images/hotel_all.png",
    price: 60,
    currency: "MYR",
    description: "Find the perfect place to stay in Changloon with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 8.2,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 547,
    code: "HOTELSALL-KDH207-GRN",
    name: "Hotels in Gurun, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc86875",
    image: "/images/hotel_all.png",
    price: 85,
    currency: "MYR",
    description: "Find the perfect place to stay in Gurun with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.0,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 548,
    code: "HOTELSALL-KDH208-JRA",
    name: "Hotels in Jitra, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc25635",
    image: "/images/hotel_all.png",
    price: 71,
    currency: "MYR",
    description: "Find the perfect place to stay in Jitra with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.5,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 549,
    code: "HOTELSALL-KDH209-KBS",
    name: "Hotels in Kepala Batas, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc86874",
    image: "/images/hotel_all.png",
    price: 71,
    currency: "MYR",
    description: "Find the perfect place to stay in Kepala Batas with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.5,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 550,
    code: "HOTELSALL-KDH210-KKH",
    name: "Hotels in Kuala Kedah, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319268",
    image: "/images/hotel_all.png",
    price: 167,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Kedah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 9.4,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 551,
    code: "HOTELSALL-KDH211-KKT",
    name: "Hotels in Kuala Ketil, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc322615",
    image: "/images/hotel_all.png",
    price: 50,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Ketil with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 552,
    code: "HOTELSALL-KDH212-KNR",
    name: "Hotels in Kuala Nerang, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc44881",
    image: "/images/hotel_all.png",
    price: 71,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Nerang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.5,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 553,
    code: "HOTELSALL-KDH213-KLM",
    name: "Hotels in Kulim, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc280",
    image: "/images/hotel_all.png",
    price: 69,
    currency: "MYR",
    description: "Find the perfect place to stay in Kulim with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 7.8,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 554,
    code: "HOTELSALL-KDH214-LWI",
    name: "Hotels in Langkawi, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc45113",
    image: "/images/hotel_all.png",
    price: 47,
    currency: "MYR",
    description: "Find the perfect place to stay in Langkawi with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.5,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 555,
    code: "HOTELSALL-KDH215-LNS",
    name: "Hotels in Lunas, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc155089",
    image: "/images/hotel_all.png",
    price: 69,
    currency: "MYR",
    description: "Find the perfect place to stay in Lunas with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 7.8,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 556,
    code: "HOTELSALL-KDH216-PNG",
    name: "Hotels in Pendang, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110517",
    image: "/images/hotel_all.png",
    price: 89,
    currency: "MYR",
    description: "Find the perfect place to stay in Pendang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 9.3,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 557,
    code: "HOTELSALL-KDH217-PSN",
    name: "Hotels in Pokok Sena, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110623",
    image: "/images/hotel_all.png",
    price: 52,
    currency: "MYR",
    description: "Find the perfect place to stay in Pokok Sena with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.5,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 558,
    code: "HOTELSALL-KDH218-SRG",
    name: "Hotels in Serdang, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc120281",
    image: "/images/hotel_all.png",
    price: 62,
    currency: "MYR",
    description: "Find the perfect place to stay in Serdang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 559,
    code: "HOTELSALL-KDH219-SPT",
    name: "Hotels in Sungai Petani, Kedah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc9822",
    image: "/images/hotel_all.png",
    price: 50,
    currency: "MYR",
    description: "Find the perfect place to stay in Sungai Petani with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["kedah", "all"],
  },
  {
    id: 560,
    code: "HOTELSALL-NG9220-BHU",
    name: "Hotels in Bahau, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc100436",
    image: "/images/hotel_all.png",
    price: 73,
    currency: "MYR",
    description: "Find the perfect place to stay in Bahau with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 7.8,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 561,
    code: "HOTELSALL-NG9221-GMS",
    name: "Hotels in Gemas, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc100588",
    image: "/images/hotel_all.png",
    price: 71,
    currency: "MYR",
    description: "Find the perfect place to stay in Gemas with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.3,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 562,
    code: "HOTELSALL-NG9222-KLW",
    name: "Hotels in Kuala Klawang, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc95300",
    image: "/images/hotel_all.png",
    price: 198,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Klawang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 9.1,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 563,
    code: "HOTELSALL-NG9223-KPL",
    name: "Hotels in Kuala Pilah, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc10537",
    image: "/images/hotel_all.png",
    price: 57,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Pilah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.1,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 564,
    code: "HOTELSALL-NG9224-LBU",
    name: "Hotels in Labu, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110465",
    image: "/images/hotel_all.png",
    price: 48,
    currency: "MYR",
    description: "Find the perfect place to stay in Labu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 565,
    code: "HOTELSALL-NG9225-PSP",
    name: "Hotels in Pasir Panjang, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110533",
    image: "/images/hotel_all.png",
    price: 74,
    currency: "MYR",
    description: "Find the perfect place to stay in Pasir Panjang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.9,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 566,
    code: "HOTELSALL-NG9226-PDC",
    name: "Hotels in Port Dickson, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc47371",
    image: "/images/hotel_all.png",
    price: 44,
    currency: "MYR",
    description: "Find the perfect place to stay in Port Dickson with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.0,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 567,
    code: "HOTELSALL-NG9227-RMB",
    name: "Hotels in Rembau, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc108679",
    image: "/images/hotel_all.png",
    price: 44,
    currency: "MYR",
    description: "Find the perfect place to stay in Rembau with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.3,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 568,
    code: "HOTELSALL-NG9228-RPN",
    name: "Hotels in Rompin, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc322418",
    image: "/images/hotel_all.png",
    price: 73,
    currency: "MYR",
    description: "Find the perfect place to stay in Rompin with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 7.8,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 569,
    code: "HOTELSALL-NG9229-SRB",
    name: "Hotels in Seremban, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc17718",
    image: "/images/hotel_all.png",
    price: 44,
    currency: "MYR",
    description: "Find the perfect place to stay in Seremban with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.3,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 570,
    code: "HOTELSALL-NG9230-SRS",
    name: "Hotels in Si Rusa, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc86921",
    image: "/images/hotel_all.png",
    price: 74,
    currency: "MYR",
    description: "Find the perfect place to stay in Si Rusa with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.9,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 571,
    code: "HOTELSALL-NG9231-TMP",
    name: "Hotels in Tampin, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc312237",
    image: "/images/hotel_all.png",
    price: 25,
    currency: "MYR",
    description: "Find the perfect place to stay in Tampin with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.4,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 572,
    code: "HOTELSALL-NG9232-TKG",
    name: "Hotels in Teluk Kemang, Negeri Sembilan",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc105044",
    image: "/images/hotel_all.png",
    price: 74,
    currency: "MYR",
    description: "Find the perfect place to stay in Teluk Kemang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.9,
    total_review: "",
    tags: ["negerisembilan", "all"],
  },
  {
    id: 573,
    code: "HOTELSALL-SBH233-BFT",
    name: "Hotels in Beaufort, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc32002",
    image: "/images/hotel_all.png",
    price: 170,
    currency: "MYR",
    description: "Find the perfect place to stay in Beaufort with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 8.6,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 574,
    code: "HOTELSALL-SBH234-BGA",
    name: "Hotels in Bongawan, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc94333",
    image: "/images/hotel_all.png",
    price: 40,
    currency: "MYR",
    description: "Find the perfect place to stay in Bongawan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 9.2,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 575,
    code: "HOTELSALL-SBH235-KNG",
    name: "Hotels in Keningau, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc44672",
    image: "/images/hotel_all.png",
    price: 46,
    currency: "MYR",
    description: "Find the perfect place to stay in Keningau with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 7.6,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 576,
    code: "HOTELSALL-SBH236-KBT",
    name: "Hotels in Kinabatangan, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc316691",
    image: "/images/hotel_all.png",
    price: 521,
    currency: "MYR",
    description: "Find the perfect place to stay in Kinabatangan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 9.2,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 577,
    code: "HOTELSALL-SBH237-KNR",
    name: "Hotels in Kinarut, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc69384",
    image: "/images/hotel_all.png",
    price: 40,
    currency: "MYR",
    description: "Find the perfect place to stay in Kinarut with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.6,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 578,
    code: "HOTELSALL-SBH238-KBD",
    name: "Hotels in Kota Belud, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc76590",
    image: "/images/hotel_all.png",
    price: 65,
    currency: "MYR",
    description: "Find the perfect place to stay in Kota Belud with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 8.8,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 579,
    code: "HOTELSALL-SBH239-KKB",
    name: "Hotels in Kota Kinabalu, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc28359",
    image: "/images/hotel_all.png",
    price: 44,
    currency: "MYR",
    description: "Find the perfect place to stay in Kota Kinabalu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.6,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 580,
    code: "HOTELSALL-SBH240-KMR",
    name: "Hotels in Kota Marudu, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc329584",
    image: "/images/hotel_all.png",
    price: 208,
    currency: "MYR",
    description: "Find the perfect place to stay in Kota Marudu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 10,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 581,
    code: "HOTELSALL-SBH241-KLP",
    name: "Hotels in Kuala Penyu, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc112722",
    image: "/images/hotel_all.png",
    price: 240,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuala Penyu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 7.4,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 582,
    code: "HOTELSALL-SBH242-KDT",
    name: "Hotels in Kudat, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc53181",
    image: "/images/hotel_all.png",
    price: 99,
    currency: "MYR",
    description: "Find the perfect place to stay in Kudat with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 9.0,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 583,
    code: "HOTELSALL-SBH243-KDS",
    name: "Hotels in Kundasang, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc91911",
    image: "/images/hotel_all.png",
    price: 35,
    currency: "MYR",
    description: "Find the perfect place to stay in Kundasang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.7,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 584,
    code: "HOTELSALL-SBH244-LHD",
    name: "Hotels in Lahad Datu, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc53235",
    image: "/images/hotel_all.png",
    price: 53,
    currency: "MYR",
    description: "Find the perfect place to stay in Lahad Datu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.7,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 585,
    code: "HOTELSALL-SBH245-PPR",
    name: "Hotels in Papar, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc100437",
    image: "/images/hotel_all.png",
    price: 40,
    currency: "MYR",
    description: "Find the perfect place to stay in Papar with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 8.2,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 586,
    code: "HOTELSALL-SBH246-PNP",
    name: "Hotels in Penampang, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc317360",
    image: "/images/hotel_all.png",
    price: 44,
    currency: "MYR",
    description: "Find the perfect place to stay in Penampang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.6,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 587,
    code: "HOTELSALL-SBH247-PTT",
    name: "Hotels in Putatan, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc321201",
    image: "/images/hotel_all.png",
    price: 44,
    currency: "MYR",
    description: "Find the perfect place to stay in Putatan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.6,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 588,
    code: "HOTELSALL-SBH248-RNU",
    name: "Hotels in Ranau, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc47646",
    image: "/images/hotel_all.png",
    price: 63,
    currency: "MYR",
    description: "Find the perfect place to stay in Ranau with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.8,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 589,
    code: "HOTELSALL-SBH249-SDK",
    name: "Hotels in Sandakan, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc33033",
    image: "/images/hotel_all.png",
    price: 48,
    currency: "MYR",
    description: "Find the perfect place to stay in Sandakan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.8,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 590,
    code: "HOTELSALL-SBH250-SRN",
    name: "Hotels in Semporna, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc54456",
    image: "/images/hotel_all.png",
    price: 45,
    currency: "MYR",
    description: "Find the perfect place to stay in Semporna with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.6,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 591,
    code: "HOTELSALL-SBH251-SIG",
    name: "Hotels in Sipitang, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc54538",
    image: "/images/hotel_all.png",
    price: 228,
    currency: "MYR",
    description: "Find the perfect place to stay in Sipitang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 7.0,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 592,
    code: "HOTELSALL-SBH252-TMB",
    name: "Hotels in Tambunan, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc316688",
    image: "/images/hotel_all.png",
    price: 74,
    currency: "MYR",
    description: "Find the perfect place to stay in Tambunan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 7.9,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 593,
    code: "HOTELSALL-SBH253-TWU",
    name: "Hotels in Tawau, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc4414",
    image: "/images/hotel_all.png",
    price: 64,
    currency: "MYR",
    description: "Find the perfect place to stay in Tawau with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.7,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 594,
    code: "HOTELSALL-SBH254-TPK",
    name: "Hotels in Telipok, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc317359",
    image: "/images/hotel_all.png",
    price: 44,
    currency: "MYR",
    description: "Find the perfect place to stay in Telipok with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 9.6,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 595,
    code: "HOTELSALL-SBH255-TNM",
    name: "Hotels in Tenom, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110479",
    image: "/images/hotel_all.png",
    price: 128,
    currency: "MYR",
    description: "Find the perfect place to stay in Tenom with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 9.4,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 596,
    code: "HOTELSALL-SBH256-TRN",
    name: "Hotels in Tuaran, Sabah",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc49611",
    image: "/images/hotel_all.png",
    price: 68,
    currency: "MYR",
    description: "Find the perfect place to stay in Tuaran with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 9.0,
    total_review: "",
    tags: ["sabah", "all"],
  },
  {
    id: 597,
    code: "HOTELSALL-SAR257-BRO",
    name: "Hotels in Bario, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc51937",
    image: "/images/hotel_all.png",
    price: 66,
    currency: "MYR",
    description: "Find the perfect place to stay in Bario with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 0,
    rating: 8.8,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 598,
    code: "HOTELSALL-SAR258-BAU",
    name: "Hotels in Bau, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc110509",
    image: "/images/hotel_all.png",
    price: 89,
    currency: "MYR",
    description: "Find the perfect place to stay in Bau with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.5,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 599,
    code: "HOTELSALL-SAR259-BTR",
    name: "Hotels in Bintangor, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319319",
    image: "/images/hotel_all.png",
    price: 41,
    currency: "MYR",
    description: "Find the perfect place to stay in Bintangor with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 7.3,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 600,
    code: "HOTELSALL-SAR260-BTL",
    name: "Hotels in Bintulu, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc41422",
    image: "/images/hotel_all.png",
    price: 37,
    currency: "MYR",
    description: "Find the perfect place to stay in Bintulu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.8,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 601,
    code: "HOTELSALL-SAR261-KPT",
    name: "Hotels in Kapit, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc52998",
    image: "/images/hotel_all.png",
    price: 73,
    currency: "MYR",
    description: "Find the perfect place to stay in Kapit with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 8.0,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 602,
    code: "HOTELSALL-SAR262-KSM",
    name: "Hotels in Kota Samarahan, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc149020",
    image: "/images/hotel_all.png",
    price: 40,
    currency: "MYR",
    description: "Find the perfect place to stay in Kota Samarahan with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 603,
    code: "HOTELSALL-SAR263-KHG",
    name: "Hotels in Kuching, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc2272",
    image: "/images/hotel_all.png",
    price: 40,
    currency: "MYR",
    description: "Find the perfect place to stay in Kuching with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 604,
    code: "HOTELSALL-SAR264-LWS",
    name: "Hotels in Lawas, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc53271",
    image: "/images/hotel_all.png",
    price: 75,
    currency: "MYR",
    description: "Find the perfect place to stay in Lawas with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.4,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 605,
    code: "HOTELSALL-SAR265-LIM",
    name: "Hotels in Limbang, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc53308",
    image: "/images/hotel_all.png",
    price: 65,
    currency: "MYR",
    description: "Find the perfect place to stay in Limbang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 6.8,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 606,
    code: "HOTELSALL-SAR266-LDU",
    name: "Hotels in Lundu, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc87284",
    image: "/images/hotel_all.png",
    price: 206,
    currency: "MYR",
    description: "Find the perfect place to stay in Lundu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 8.9,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 607,
    code: "HOTELSALL-SAR267-MRI",
    name: "Hotels in Miri, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc30828",
    image: "/images/hotel_all.png",
    price: 41,
    currency: "MYR",
    description: "Find the perfect place to stay in Miri with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 5,
    rating: 8.8,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 608,
    code: "HOTELSALL-SAR268-MKH",
    name: "Hotels in Mukah, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc53710",
    image: "/images/hotel_all.png",
    price: 54,
    currency: "MYR",
    description: "Find the perfect place to stay in Mukah with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.4,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 609,
    code: "HOTELSALL-SAR269-SRK",
    name: "Hotels in Sarikei, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc319258",
    image: "/images/hotel_all.png",
    price: 41,
    currency: "MYR",
    description: "Find the perfect place to stay in Sarikei with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 2,
    rating: 7.3,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 610,
    code: "HOTELSALL-SAR270-SBU",
    name: "Hotels in Sibu, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc5154",
    image: "/images/hotel_all.png",
    price: 37,
    currency: "MYR",
    description: "Find the perfect place to stay in Sibu with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 4,
    rating: 8.9,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 611,
    code: "HOTELSALL-SAR271-SMG",
    name: "Hotels in Simanggang, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc54522",
    image: "/images/hotel_all.png",
    price: 52,
    currency: "MYR",
    description: "Find the perfect place to stay in Simanggang with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.7,
    total_review: "",
    tags: ["sarawak", "all"],
  },
  {
    id: 612,
    code: "HOTELSALL-SAR272-SRA",
    name: "Hotels in Sri Aman, Sarawak",
    link: "https://www.kayak.com.my/in?a=kan_262812_573418&encoder=27_1&enc_pid=deeplinks&url=%2Fhotels-dateless%2Fc7081",
    image: "/images/hotel_all.png",
    price: 52,
    currency: "MYR",
    description: "Find the perfect place to stay in Sri Aman with our curated list of hotels, from luxurious options to budget-friendly choices, all conveniently located to make your stay unforgettable.",
    hotel_class: 3,
    rating: 8.7,
    total_review: "",
    tags: ["sarawak", "all"],
  },
];
